import { runUpdateThingHandler } from './thingHandlers';
import { Api, ThingEvent, Thing, ThingUpdate } from './types';
import { Engine } from 'matter-js';
import { reportThingChangesToPhysicsEngineFromLocal } from './usePhysicsEngineIfIOs';
import {
  thingUpdate,
  useAppDispatch,
  useAppSelector,
  getNewThings,
  reportGameEngineThingChangesToServer,
} from './useData';

export function ThingWrapperForPlayScreen({
  physicsEngine,
  children,
  thingIndex,
  things,
  api,
}: {
  physicsEngine: Engine;
  children: React.ReactNode;
  thingIndex: number;
  things: Array<Thing>;
  api: Api;
}) {
  const handlersByType = useAppSelector((state) => state.handlersByType);
  const dispatch = useAppDispatch();

  return (
    <div
      onTouchStart={(e) => {
        const newThings = getNewThings(things);

        runUpdateThingHandler(
          dispatch,
          physicsEngine,
          handlersByType,
          newThings[thingIndex],
          ThingEvent.ON_THING_TAP,
          [api, newThings]
        );

        newThings.forEach((newThing: Thing, i: number) => {
          reportThingChangesToPhysicsEngineFromLocal(
            physicsEngine,
            things[i],
            newThing
          );

          reportGameEngineThingChangesToServer(
            things[i],
            newThing,
            i,
            (index: number, update: ThingUpdate) =>
              dispatch(thingUpdate({ index, update }))
          );
        });

        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
}
