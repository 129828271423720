import { Button } from 'evergreen-ui';
import { useGetInterruptibleResponseStreamed } from './useGetInterruptibleResponseStreamed';
import { useCallback } from 'react';
import {
  getProgrammerSystemMessageContentPrompt,
  canGenerateCode,
  getCodeCodeIfExists,
} from './prompts';
import { Api, Artifact, Message, Tab, View, assertTruthy } from './types';
import { uniq } from 'lodash';
import { SpecEditor } from './SpecEditor';
import { SelectedThingList } from './SelectedThingList';
import { useEditorOnlyStateContext } from './substantiate';
import { mergeCodeOrThrowIfCodeDoesNotCompile } from './dispatch';
import {
  useAppSelector,
  getConversation,
  getTextForSelectedThingTypes,
} from './useData';

export function SpecPanel({
  onRunCode,
  onDeleteGame,
  selectedThingTypes,
  api,
  codeView,
  setCodeView,
}: {
  onRunCode: (code: string) => void;
  onDeleteGame: () => void;
  selectedThingTypes: Array<string>;
  api: Api;
  codeView: string;
  setCodeView: (codeView: View) => void;
}) {
  const things = useAppSelector((state) => state.things);
  const playScreenSize = useAppSelector((state) => state.playScreenSize);

  const { editorAppState, dispatchEditorAppState } =
    useEditorOnlyStateContext();
  const {
    selectedThingsIndices,
    spec,
    indexOfCodeMessageBeingViewed,
    code,
    specAtTimeOfLastCodeGeneration,
  } = editorAppState;

  const updateIndexOfCodeMessageBeingViewed = useCallback(
    (index: number | null) => {
      dispatchEditorAppState({
        type: 'updateIndexOfCodeMessageBeingViewed',
        indexOfCodeMessageBeingViewed: index,
      });
      setCodeView(View.PATCH);
    },
    [dispatchEditorAppState, setCodeView]
  );

  const getInterruptibleResponseStreamed =
    useGetInterruptibleResponseStreamed();

  const onGenerateCode = useCallback(() => {
    dispatchEditorAppState({
      type: 'addConversationMessage',
      artifact: Artifact.CODE,
      message: {
        sender: 'user',
        text: 'Now generate code',
      },
    });

    dispatchEditorAppState({
      type: 'addConversationMessage',
      artifact: Artifact.CODE,
      message: {
        sender: 'bot',
        text: '...',
        isComplete: false,
      },
    });

    const codeMessageIndex =
      getConversation(editorAppState, Artifact.CODE).length + 1;

    dispatchEditorAppState({
      type: 'updateSelectedTab',
      selectedTab: Tab.CODE,
    });

    getInterruptibleResponseStreamed(
      getProgrammerSystemMessageContentPrompt(
        spec,
        specAtTimeOfLastCodeGeneration,
        playScreenSize,
        code,
        uniq(things.map((thing) => thing.type))
      ),
      [],
      '',
      (output) => {
        const updatedMessage: Message = {
          sender: 'bot',
          text: output.text,
          isComplete: output.isComplete,
        };

        dispatchEditorAppState({
          type: 'setConversationMessage',
          artifact: Artifact.CODE,
          messageIndex: codeMessageIndex,
          message: updatedMessage,
        });

        if (indexOfCodeMessageBeingViewed !== codeMessageIndex) {
          updateIndexOfCodeMessageBeingViewed(codeMessageIndex);
        }

        if (codeView !== View.PATCH) {
          setCodeView(View.PATCH);
        }

        if (output.isComplete) {
          // Merge and run code right away
          const justTheCode = getCodeCodeIfExists(updatedMessage);
          assertTruthy(justTheCode);

          const mergedCode = mergeCodeOrThrowIfCodeDoesNotCompile(
            code,
            justTheCode,
            { type: 'genSelected', selectedThingTypes }
          );

          dispatchEditorAppState({ type: 'updateCode', code: mergedCode });

          dispatchEditorAppState({
            type: 'updateSpecAtTimeOfLastCodeGeneration',
            value: spec,
          });
          dispatchEditorAppState({
            type: 'clearConversation',
            artifact: Artifact.CODE,
          });
          setCodeView(View.FULL);
          onRunCode(mergedCode);
        }
      }
    );
  }, [
    code,
    codeView,
    dispatchEditorAppState,
    editorAppState,
    getInterruptibleResponseStreamed,
    indexOfCodeMessageBeingViewed,
    onRunCode,
    playScreenSize,
    selectedThingTypes,
    setCodeView,
    spec,
    specAtTimeOfLastCodeGeneration,
    things,
    updateIndexOfCodeMessageBeingViewed,
  ]);

  const specForSelectedThingTypes = getTextForSelectedThingTypes(
    spec,
    selectedThingTypes
  );

  return (
    <>
      <div className="flex justify-between items-center pb1">
        <SelectedThingList
          selectedThingsIndices={selectedThingsIndices}
          things={things}
          api={api}
        />

        <div className="flex items-center">
          <div
            className="text-red normal pointer"
            onClick={() => {
              const shouldDelete = window.confirm(
                'Are you sure you want to delete your game and start over?'
              );

              if (!shouldDelete) {
                return;
              }

              onDeleteGame();
            }}
          >
            Delete game
          </div>

          <Button
            size="small"
            className="ml1"
            onClick={onGenerateCode}
            disabled={
              !canGenerateCode(specForSelectedThingTypes, selectedThingsIndices)
            }
          >
            Generate code
          </Button>
        </div>
      </div>

      <div className="white p1 rounded height-full">
        <SpecEditor
          value={specForSelectedThingTypes}
          onChange={(value: string) => {
            dispatchEditorAppState({
              type: 'updateSpec',
              selectedThingTypes,
              text: value,
            });
          }}
          onSubmit={onGenerateCode}
        />
      </div>
    </>
  );
}
