import classNames from 'classnames';
import { ThingRenderedAsIcon } from './ThingRenderedAsIcon';
import {
  Api,
  CodeEditingSelectedThingTypeAndIfExistsEventType,
  Thing,
  ThingEvent,
} from './types';
import { useAppSelector } from './useData';
import { getEventEnglishDescription, getHandlerStub } from './thingHandlers';
import { useEditorOnlyStateContext } from './substantiate';
import { isEqual, uniqBy } from 'lodash';
import { Button, Menu, Popover } from 'evergreen-ui';
import { getBoundEventTypesForThingType } from './runCode';
import { mergeHandlerOrThrowIfCodeDoesNotCompile } from './dispatch';

export function HandlerSelectionList({
  things,
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
  onRunCode,
  api,
}: {
  things: Array<Thing>;
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse: CodeEditingSelectedThingTypeAndIfExistsEventType;
  onRunCode: (code: string) => void;
  api: Api;
}) {
  const handlersByType = useAppSelector((state) => state.handlersByType);

  return (
    <div className="flex flex-column">
      {things.length > 0
        ? uniqBy(things, (t) => t.type).map((thing, i) => {
            const eventTypes = getBoundEventTypesForThingType(
              thing.type,
              handlersByType
            );

            return (
              <div
                key={thing.id}
                className={classNames('flex items-center', { mt1: i > 0 })}
              >
                <div className="mr1">
                  <ThingRenderedAsIcon
                    thing={thing}
                    api={api}
                    sizeInPixels={20}
                  />
                </div>

                <div className="mr1 stronger">{thing.type}</div>

                <div className="flex items-center">
                  <BoundHandlers
                    thing={thing}
                    codeEditingSelectedThingTypeAndEventTypeIfExistsToUse={
                      codeEditingSelectedThingTypeAndEventTypeIfExistsToUse
                    }
                    eventTypes={eventTypes}
                  />

                  <div className={classNames({ ml1: eventTypes.length > 0 })}>
                    <AddHandler
                      thingType={thing.type}
                      eventTypes={eventTypes}
                      codeEditingSelectedThingTypeAndEventTypeIfExistsToUse={
                        codeEditingSelectedThingTypeAndEventTypeIfExistsToUse
                      }
                      onRunCode={onRunCode}
                    />
                  </div>
                </div>
              </div>
            );
          })
        : 'Nothing selected'}
    </div>
  );
}

function BoundHandlers({
  thing,
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
  eventTypes,
}: {
  thing: Thing;
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse: CodeEditingSelectedThingTypeAndIfExistsEventType;
  eventTypes: Array<ThingEvent>;
}) {
  const { dispatchEditorAppState } = useEditorOnlyStateContext();

  return (
    <>
      {eventTypes.map((eventTypeString) => {
        const eventType = eventTypeString as ThingEvent;
        const thingTypeAndEventType = {
          thingType: thing.type,
          eventType,
        };

        const isSelected = isEqual(
          codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
          thingTypeAndEventType
        );

        return (
          <div
            key={eventType}
            className="flex items-center text-blue pointer"
            onClick={() => {
              dispatchEditorAppState({
                type: 'updateCodeEditingSelectedThingTypeAndEventTypeIfExists',
                thingTypeAndEventTypeIfExists: thingTypeAndEventType,
              });
            }}
          >
            <div
              className={classNames('flex px1 py-half', {
                'blue-light1 text-light': isSelected,
              })}
            >
              <div style={{ fontSize: 16 }}>{getIconForEvent(eventType)}</div>

              <div className="ml-half">
                {getEventEnglishDescription(eventType)}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

function AddHandler({
  thingType,
  eventTypes,
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
  onRunCode,
}: {
  thingType: string;
  eventTypes: Array<ThingEvent>;
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse: CodeEditingSelectedThingTypeAndIfExistsEventType;
  onRunCode: (code: string) => void;
}) {
  const { editorAppState, dispatchEditorAppState } =
    useEditorOnlyStateContext();
  const { code } = editorAppState;

  return (
    <Popover
      position="bottom-left"
      content={
        <Menu>
          <Menu.Group>
            {Object.values(ThingEvent)
              .filter((eventType) => {
                return !eventTypes.includes(eventType);
              })
              .map((eventType) => {
                return (
                  <Menu.Item
                    key={eventType}
                    className="no-user-select"
                    icon={<>{getIconForEvent(eventType)}</>}
                    onSelect={() => {
                      const newCode = getHandlerStub(eventType);

                      const mergedCode =
                        mergeHandlerOrThrowIfCodeDoesNotCompile(code, newCode, {
                          type: 'textEdit',
                          thingType,
                          eventType,
                        });

                      dispatchEditorAppState({
                        type: 'updateCode',
                        code: mergedCode,
                      });

                      dispatchEditorAppState({
                        type: 'updateCodeEditingSelectedThingTypeAndEventTypeIfExists',
                        thingTypeAndEventTypeIfExists: {
                          thingType,
                          eventType,
                        },
                      });

                      onRunCode(mergedCode);
                    }}
                  >
                    {getEventEnglishDescription(eventType)}
                  </Menu.Item>
                );
              })}
          </Menu.Group>
        </Menu>
      }
    >
      <Button size="small">➕ Handler</Button>
    </Popover>
  );
}

export function getIconForEvent(eventType: ThingEvent) {
  switch (eventType) {
    case ThingEvent.ON_TICK:
      return '⏰';
    case ThingEvent.ON_THING_TAP:
      return '👆';
    case ThingEvent.ON_WORLD_TAP:
      return '🌎';
    case ThingEvent.ON_EVENT:
      return '📣';
    case ThingEvent.ON_COLLIDE:
      return '🤜';
    case ThingEvent.ON_COLLIDING:
      return '🤛';
    case ThingEvent.ON_UNCOLLIDE:
      return '🤝';
    case ThingEvent.ON_DRAW:
      return '🎨';
    default:
      throw new Error(`Unknown event type: ${eventType}`);
  }
}
