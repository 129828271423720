import { Editor } from '@monaco-editor/react';
import { KeyMod, KeyCode } from 'monaco-editor';
import { useEffect, useRef } from 'react';
import type monaco from 'monaco-editor';

export function SpecEditor({
  value,
  onChange,
  onSubmit,
}: {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}) {
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  useEffect(() => {
    if (!editorRef.current) {
      return () => {};
    }

    const dispose = editorRef.current.addAction({
      id: 'executeCurrentAndAdvance',
      label: 'Execute Block and Advance',
      keybindings: [KeyMod.CtrlCmd | KeyCode.Enter],
      run: () => {
        onSubmit();
      },
    });

    return () => {
      if (!dispose) {
        return;
      }

      return dispose.dispose();
    };
  }, [onSubmit]);

  return (
    <Editor
      onMount={(editor) => {
        editorRef.current = editor;
      }}
      className="border-box rounded"
      options={{
        tabSize: 2,
        lineNumbers: 'off',
        renderLineHighlight: 'none',
        guides: {
          indentation: false,
          bracketPairs: false,
          bracketPairsHorizontal: false,
          highlightActiveBracketPair: false,
          highlightActiveIndentation: false,
        },
        minimap: { enabled: false },
        hover: { enabled: false },
        scrollBeyondLastLine: false,
        matchBrackets: 'never',
        hideCursorInOverviewRuler: true,
        overviewRulerBorder: false,
        overviewRulerLanes: 0,
        folding: false,
        glyphMargin: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        wordWrap: 'on',
      }}
      onChange={(value) => {
        if (value === undefined) {
          return;
        }

        onChange(value);
      }}
      defaultLanguage="markdown"
      value={value}
    />
  );
}
