import {
  Button,
  CrossIcon,
  defaultTheme,
  IconButton,
  mergeTheme,
  Pane,
  Popover,
  Select,
  Text,
  ThemeProvider,
} from 'evergreen-ui';
import { assertTruthy, Thing, ThingUpdate, Fps } from './types';
import { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useEditorOnlyStateContext } from './substantiate';
import {
  updateFps,
  updateIsPaused,
  useAppDispatch,
  useAppSelector,
  getSimulatorUrl,
  getEditorStorageId,
} from './useData';

export function Toolbar({
  isIOsConnected,
  onCreateThing,
}: {
  isIOsConnected: boolean;
  onCreateThing: (update: ThingUpdate) => Thing;
}) {
  const things = useAppSelector((state) => state.things);
  const { editorAppState } = useEditorOnlyStateContext();
  const { selectedThingsIndices } = editorAppState;
  const soleSelectedThingIfExists =
    selectedThingsIndices.length === 1
      ? things[selectedThingsIndices[0]]
      : null;

  return (
    <div className="flex items-center pt1">
      <PlayPauseButton />

      <div className="flex">
        <SelectFps />
      </div>

      <Button className="ml1" onClick={() => onCreateThing({})}>
        ➕ Add thing
      </Button>

      <Button
        className="ml1"
        onClick={() => {
          assertTruthy(soleSelectedThingIfExists);
          onCreateThing({ parentId: soleSelectedThingIfExists.id });
        }}
        disabled={!soleSelectedThingIfExists}
      >
        ➕ Add child
      </Button>

      <div className="flex items-center ml-half">
        <IsIOsConnected isIOsConnected={isIOsConnected} />
      </div>
    </div>
  );
}

function PlayPauseButton() {
  const isPaused = useAppSelector((state) => state.isPaused);
  const dispatch = useAppDispatch();

  const theme = mergeTheme(defaultTheme, {
    components: {
      Button: {
        appearances: {
          playing: {
            color: 'white',
            backgroundColor: '#D14343',
            _hover: {
              backgroundColor: '#C03232',
            },
          },
          paused: {
            color: 'white',
            backgroundColor: '#429777',
            _hover: {
              backgroundColor: '#317159',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider value={theme}>
      <Button
        width={70}
        appearance={isPaused ? 'paused' : 'playing'}
        onClick={() => dispatch(updateIsPaused({ isPaused: !isPaused }))}
      >
        {isPaused ? '▶' : <div className="strongest">| |</div>}
      </Button>
    </ThemeProvider>
  );
}

function SelectFps() {
  const fps = useAppSelector((state) => state.fps);
  const dispatch = useAppDispatch();

  return (
    <Select
      className="ml1"
      value={fps}
      onChange={(e) => {
        const fpsAsNumber = parseInt(e.target.value);
        dispatch(updateFps({ fps: fpsAsNumber }));
      }}
    >
      {Object.values(Fps).map((fps) => (
        <option key={fps} value={fps}>
          {fps}
        </option>
      ))}
    </Select>
  );
}

function IsIOsConnected({ isIOsConnected }: { isIOsConnected: boolean }) {
  const [isPopoverShown, setIsPopoverShown] = useState(!isIOsConnected);
  useEffect(() => {
    if (!isIOsConnected) {
      setIsPopoverShown(true);
    }
  }, [isIOsConnected]);

  const url = getSimulatorUrl(window, getEditorStorageId(window));

  return (
    <div style={{ fontSize: 25, paddingTop: 2 }}>
      {isIOsConnected ? (
        '📱'
      ) : (
        <Popover
          content={({ close }: { close: () => void }) => {
            return (
              <Pane
                display="flex"
                flexDirection="column"
                width={200}
                alignItems="center"
                justifyContent="center"
              >
                <div className="flex width-full justify-end">
                  <IconButton
                    icon={CrossIcon}
                    appearance="minimal"
                    size="small"
                    onClick={close}
                  />
                </div>

                <div className="flex flex-column px1">
                  <Text textAlign="justify">
                    To run the game, visit this <a href={url}>link</a> on your
                    phone or scan the QR code below
                  </Text>

                  <div className="pt1 pb-half">
                    <QRCodeSVG size={200 - 16} value={url} />
                  </div>
                </div>
              </Pane>
            );
          }}
          isShown={isPopoverShown}
          onClose={() => setIsPopoverShown(false)}
          onOpen={() => setIsPopoverShown(true)}
          shouldCloseOnExternalClick={false}
          shouldCloseOnEscapePress={false}
          bringFocusInside={false}
          position="bottom-right"
        >
          <div className="pointer">📵</div>
        </Popover>
      )}
    </div>
  );
}
