import { Dimensions, Message, Spec } from './types';
import { createPatch } from 'diff';

export function getProgrammerSystemMessageContentPrompt(
  spec: Spec,
  specAtTimeOfLastCodeGeneration: Spec,
  screenSize: Dimensions,
  code: string,
  thingTypesThatExistInGame: Array<string>
) {
  const existingSpec = getTextSpec(
    specAtTimeOfLastCodeGeneration,
    screenSize,
    thingTypesThatExistInGame
  );
  const latestSpec = getTextSpec(spec, screenSize, thingTypesThatExistInGame);

  const prompt = `
You are an expert coder building a video game for the web view on an iOS device.
In response to all requests, output JSON in the format described in the '# JSON format' section.
When you output JSON, begin with "Code:" then output the JSON with a \`\`\` fence around it.
Put absolutely no other text explaining the JSON after the fence.
The JSON should implement the spec in the '# Spec' section below.

# Repeat as little of the existing JSON as possible

Assume that the JSON in the '# Existing JSON' section below already exists and will be merged with the JSON you output.
With that in mind, don't output parts of the JSON that already exist and don't need to change.
However, if the JSON implements something not mentioned in the spec, that info in the spec was probably removed and you should omit that functionality from the JSON you output.
If you want to change some of the existing JSON, re-output just that part of the JSON.

## Example 1: Don't output handlers where the existing JSON already implements what the spec says

If the existing JSON is:

{
  "Ball": {
    "onTick": "function() onTick() {\n  this.xVelocity += 1;\n}",
    "onThingTap": "function() onThingTap() {\n  this.xVelocity += 1;\n}",
  },
  "Bat": {
    "onTick": "function() onTick() {\n  console.log('tick');\n}",
    "onThingTap": "function() onThingTap() {\n  console.log('tap');\n}",
  }
}

and you want to change the onTick handler on Bat, you would output only this JSON:

{
  "Bat": {
    "onThingTap": "function() onThingTap() {\n  console.log('tappy tap');\n}",
  }
}

# JSON format

The JSON shouldn't be surrounded by any descriptive text or code fencing.

{
  [thingType: string]: {
    // Seriously! Only add entries for handlers that actually have code (not just comments)
    [event: Event]: HandlerFunction>
  }
}

type Event = 
  | 'onTick' // Tick
  | 'onThingTap' // Tap on thing
  | 'onWorldTap' // Tap
  | 'onEvent' // Event
  | 'onCollide' // Called once when start colliding
  | 'onColliding' // Called every tick while colliding
  | 'onUncollide' // Called once when stop colliding
  | 'onDraw' // Called to generate JSX to render the thing

type HandlerFunction = string;

Notes on \`{[event: Event]: HandlerFunction>\`:
* Implement in JS the event handler behavior defined in the spec. 
* Should be a named function.
* The code should use two spaces for indentation.
* The code should include line breaks at the end of each line.

Important notes on writing code:

* It's EXTREMELY IMPORTANT that you never change the properties on otherThing in a handler. If you want to do that, put the code on the handler for otherThing!

E.g. Don't write this handler for paddle:

function(api, otherThing, things) {\n
  if (otherThing.type === 'ball') {\n
    otherThing.xVelocity = 3;\n
  }\n
}\n

Write this handler for ball instead:

function(api, otherThing, things) {\n
  if (otherThing.type === 'paddle') {\n
    this.xVelocity = 3;\n
  }\n
}\n

* Only call functions that are part of the JS standard lib or are in the api object.
* onDraw should output JSX (so its return value should not be a string but should instead be <>...</> or <div>...</div>). 
The onDraw outputted JSX should not have long lines e.g. style={{}} should put each attribute on its own line.
onDraw should almost certainly set the width and height of the outmost div rendered to thing.width and thing.height.
If onDraw renders an array of elements, the key prop should be set on each element.
* Things have a rectangular spatial area. The right side is api.rect(thing).right; the bottom is api.rect(thing).bottom
* A thing is inside the world if api.rect(thing).left >= 0 && api.rect(thing).right <= api.getScreenSize().width && api.rect(thing).top >= 0 && api.rect(thing).bottom <= api.getScreenSize().height
* Example handler function: (note that there are no types and that the body of the function starts on a new line!)

function onTick(api, things) {\n
  this.xVelocity += 1;\n
}\n

## HandlerFunction signatures

* Tap on thing - function onThingTap(this: Thing, api, things: Array<Thing>): void
* Tap - function onWorldTap(this: Thing, api, coordinates: {x: number, y: number}, things: Array<Thing>): void
* Tick - function onTick(this: Thing, api, things: Array<Thing>): void
* Event - function onEvent(this: Thing, api, event: string, data: {[key: string]: any}, things: Array<Thing>): void
* Collide - function onCollide(this: Thing, api, otherThing: ReadOnly<Thing>, things: Array<Thing>): void
* Colliding - function onColliding(this: Thing, api, otherThing: Thing, things: Array<Thing>): void
* Uncollide - function onUncollide(this: Thing, api): void
* Draw - function onDraw(thing: Thing, api, things: Array<Thing>, children: React.ReactNode): React.ReactNode

* NB: 'this: Thing' is an indication that 'this' is a Thing.  But 'this' should be OMITTED from the argument list!

type Thing = {
  type: string, // thingType of the thing.
  text: string, // optional text to display on the thing
  x: number, // x coordinate of the top left of the thing
  y: number, // y coordinate of the top left of the thing
  angle: number, // angle of the thing in degrees
  width: number, // width of the thing
  height: number, // height of the thing
  xVelocity: number, // x component of the thing's velocity.
  yVelocity: number, // y component of the thing's velocity.
  color: string, // color of the thing as a hex color string e.g. #ff0000
  isHidden: boolean, // If true, is not shown and doesn't collide with anything.
  parentId: number | null, // Things can have parents. If a thing has a parent, it moves with its parent.
  ... // Other properties can be get and set by handlers as needed.
}

If the spec refers to a piece of data without verifying which thing it's on, it's very likely on 'this'.

# api object passed to handlers

The api object Has these properties (and no others - you should never access any properties on api besides the ones listed below):
 
_: _; // lodash
vectorBetween: (startPoint: Coordinates, endPoint: Coordinates) => Coordinates;
unitVector: (vector: Coordinates) => Coordinates;
magnitude: (vector: Coordinates) => number;
rect: (thing: Thing) => { x: number, y: number, width: number, height: number, top: number, bottom: number, left: number, right: number, angle: number }
createThing: (update: Thing) => Thing;
deleteThing: (thing: Thing) => void;
duplicateThing: (thing: Thing) => Thing
fireEvent: (thing: Thing, things: Array<Thing>, event: string, data: {[key: string]: any}) => void;
getParent: (thing: Thing, things: Array<Thing>) => Thing | null;

# Existing spec:

${existingSpec}

# Latest spec (to generate code for):

${latestSpec}

# Diff between existing spec and latest spec

${createPatch('spec.md', existingSpec, latestSpec)}

# Existing JSON

${code}
`;
  console.log(prompt);
  return prompt;
}

export function getCodeCodeIfExists(message: Message) {
  const codeCode = message.text.split('Code:')[1];

  if (codeCode === undefined) {
    return null;
  }

  const code = codeCode
    .replace(/```json/, '')
    .replace(/```/, '')
    .trim();

  return !!code ? code : null;
}

export function canGenerateCode(
  spec: string,
  selectedThingsIndices: Array<number>
) {
  return spec.length > 0 && selectedThingsIndices.length > 0;
}

function getTextSpec(
  spec: Spec,
  screenSize: Dimensions,
  thingTypesThatExistInGame: Array<string>
) {
  const generalSpec = spec.find(
    (entry) => entry.selectedThingTypes.length === 0
  );

  return `# Spec

## General

Screen size: ${JSON.stringify(screenSize)}

${
  generalSpec && generalSpec.text.trim().length > 0
    ? generalSpec.text
    : 'No other general instructions.'
}

## Thing types

${spec
  .filter(
    (entry) =>
      entry.selectedThingTypes.length > 0 &&
      entry.selectedThingTypes.every((thingType) =>
        thingTypesThatExistInGame.includes(thingType)
      )
  )
  .map((entry) => {
    return `### ${entry.selectedThingTypes.join(' and ')}

${entry.text}`;
  })
  .join('\n\n')}`;
}
