// Prevents the bug where the whole window scrolls on mobile even if there's no
// hidden content.
//
// This file works by basically killing all touchmoves (which cause scrolls).
// The only ones it permits are ones on elements we want to scroll (like the
// messages list or the input textareas).
//
// To work, this requires setting overscroll-behavior: contain on the scrollable
// elements (otherwise scrolling to the limit of one of them will scroll the
// parent which we probably don't want to scroll).
//
// Note we need to use addEventListener because touchmove e.preventDefault()

import { useEffect } from 'react';

// seems to be broken in React when put directly on in JSX.
export function usePreventWholePageScrollingOnMobile() {
  useEffect(() => {
    function handleTouchMove(e: TouchEvent) {
      e.preventDefault();
    }

    document.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
}
