import { createRef, RefObject, useEffect, useState } from 'react';
import { RunningGame } from './RunningGame';
import { Api, Coordinates, Dimensions } from './types';
import { Engine } from 'matter-js';
import { useEditorOnlyStateContext } from './substantiate';

export function EditorScreen({
  physicsEngineIfExists,
  playScreenSize,
  api,
}: {
  physicsEngineIfExists: Engine | null;
  playScreenSize: Dimensions;
  api: Api;
}) {
  const { dispatchEditorAppState } = useEditorOnlyStateContext();

  const screenRef: RefObject<HTMLDivElement> = createRef();

  const [screenGlobalTopLeft, setScreenGlobalTopLeft] =
    useState<Coordinates | null>(null);
  useEffect(() => {
    if (!screenRef.current || screenGlobalTopLeft) {
      return;
    }

    const screenBoundingRect = screenRef.current.getBoundingClientRect();

    setScreenGlobalTopLeft({
      x: screenBoundingRect.x, // for border
      y: screenBoundingRect.y,
    });
  }, [screenRef, screenGlobalTopLeft]);

  return (
    <div
      ref={screenRef}
      style={{ ...playScreenSize }}
      onClick={() =>
        dispatchEditorAppState({
          type: 'updateSelectedThingsIndices',
          selectedThingsIndices: [],
        })
      }
      onKeyDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {screenGlobalTopLeft && (
        <RunningGame
          physicsEngineIfExists={physicsEngineIfExists}
          screenGlobalTopLeft={screenGlobalTopLeft}
          api={api}
        />
      )}
    </div>
  );
}
