import { useCallback } from 'react';
import { Thing, ThingUpdate } from './types';
import { imageUpdate, useAppDispatch, useAppSelector } from './useData';

export function useOnDuplicateThing(
  things: Array<Thing>,
  onCreateThing: (update: ThingUpdate) => Thing
) {
  const dispatch = useAppDispatch();
  const images = useAppSelector((state) => state.images);

  const onDuplicateThing = useCallback(
    (thing: Thing): Thing => {
      const newThing = onCreateThing({
        ...thing,
        x: thing.x + 10,
        y: thing.y + 10,
      });

      dispatch(
        imageUpdate({
          imageIndex: newThing.imageId,
          image: images[thing.imageId],
        })
      );

      things
        .filter((t) => t.parentId === thing.id)
        .forEach((childThing: Thing) => {
          const newChildThing = onCreateThing({
            ...childThing,
            x: childThing.x,
            y: childThing.y,
            parentId: newThing.id,
          });

          dispatch(
            imageUpdate({
              imageIndex: newChildThing.imageId,
              image: images[childThing.imageId],
            })
          );
        });

      return newThing;
    },
    [dispatch, images, onCreateThing, things]
  );

  return onDuplicateThing;
}
