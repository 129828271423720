import './App.css';
import { assertTruthy, Thing } from './types';
import _, { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import {
  reportCollisionSettingsChangeToPhysicsEngineFromRemote,
  reportThingMoveToPhysicsEngineFromRemote,
  reportThingSizeChangeToPhysicsEngineFromRemote,
  reportThingVelocityChangeToPhysicsEngineFromRemote,
} from './usePhysicsEngineIfIOs';
import { useEditorOnlyStateContext } from './substantiate';
import {
  thingPropertyDelete,
  thingUpdate,
  useAppDispatch,
  useAppSelector,
} from './useData';
import { CodeEditor } from './CodeEditor';

export function PropertyEditor() {
  const things = useAppSelector((state) => state.things);
  const dispatch = useAppDispatch();
  const { editorAppState } = useEditorOnlyStateContext();
  const { selectedThingsIndices } = editorAppState;

  // We assume in this code there is exactly one selected thing, otherwise it's not rendered
  const selectedThing = things[selectedThingsIndices[0]];
  assertTruthy(selectedThing);

  const [textAreaValue, setTextAreaValue] = useState<string>(
    selectedThingToString(selectedThing)
  );
  const [textAreaValueWithEditedChanges, setTextAreaValueWithEditedChanges] =
    useState<string>(textAreaValue);

  const [hasError, setHasError] = useState<boolean>(false);

  const [editedThingId, setEditedThingId] = useState<number>(selectedThing.id);

  // If new thing selected, or currently selected thing changes its data, need
  // to update textAreaValue (passed as value to CodeEditor) to show it. But we
  // avoid this updating textAreaValue unless we have to because updating it
  // loses the cursor position and moves any newly added properties to the end
  // of the object. See below for the cases where we avoid updating it.
  useEffect(() => {
    try {
      if (
        // Don't update if existing thing JS obj equals contents of editor
        // parsed into JS obj). This happens if:
        //
        // * There has been a whitespace change.
        // * A text edit has changed the content of the obj
        //   (added/removed/renamed/changed a property). In this case, the
        //   editor has the correct text so we don't need to update it.
        //
        // This all works by us keeping the real latest string in
        // textAreaValueWithEditedChanges and only changing textAreaValue (which
        // drives the editor) if we have to.
        isEqual(selectedThing, JSON.parse(textAreaValueWithEditedChanges))
      ) {
        return;
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        return;
      }

      throw new Error('Unexpected error');
    }

    setHasError(false);
    const newTextAreaValue = selectedThingToString(selectedThing);
    setTextAreaValue(newTextAreaValue);
    setTextAreaValueWithEditedChanges(newTextAreaValue);
    setEditedThingId(selectedThing.id);
  }, [selectedThing, textAreaValue, textAreaValueWithEditedChanges]);

  return (
    <CodeEditor
      value={textAreaValue}
      language="json"
      onChange={(newValue: string) => {
        // Bizarrely, onChange is fired when textAreaValue is updated even if
        // the change didn't result from an internal Monaco change like a text
        // edit. In our case, useEffect above was firing when a new thing was
        // selected. useEffect updated textAreaValue which was passed into
        // value={} which fired onChange. But useEffect was firing before
        // selectedThingsIndices prop had come in updated.  So onChange was
        // running for the previously selected object.  The outcome was that
        // selecting an object would overwrite all the attributes of the
        // previously selected object with the attributes of the newly selected
        // object.
        if (editedThingId !== selectedThing.id) {
          return;
        }

        const selectedThingIndex =
          selectedThingsIndices.length > 0 ? selectedThingsIndices[0] : null;
        if (selectedThingIndex === null) {
          return;
        }

        assertTruthy(selectedThing);

        setTextAreaValueWithEditedChanges(newValue);

        let editedThing;
        try {
          editedThing = JSON.parse(newValue);
          setHasError(false);
        } catch (e) {
          setHasError(true);
          return;
        }

        reportThingSizeChangeToPhysicsEngineFromRemote(selectedThing, {
          width: editedThing.width,
          height: editedThing.height,
        });

        reportThingMoveToPhysicsEngineFromRemote(selectedThing, {
          x: editedThing.x,
          y: editedThing.y,
        });

        reportThingVelocityChangeToPhysicsEngineFromRemote(selectedThing, {
          xVelocity: editedThing.xVelocity,
          yVelocity: editedThing.yVelocity,
        });

        reportCollisionSettingsChangeToPhysicsEngineFromRemote(
          selectedThing,
          editedThing
        );

        dispatch(
          thingUpdate({ index: selectedThingIndex, update: editedThing })
        );

        getDeletedKeys(selectedThing, editedThing).forEach((deletedKey) => {
          dispatch(
            thingPropertyDelete({
              index: selectedThingIndex,
              key: deletedKey as keyof Thing,
            })
          );
        });
      }}
      hasError={hasError}
    />
  );
}

function getDeletedKeys(originalThing: Thing, editedThing: Thing) {
  return _.differenceWith(
    Object.keys(getEditablePartsOfThing(originalThing)),
    Object.keys(editedThing),
    _.isEqual
  );
}

function getEditablePartsOfThing(thing: Thing) {
  return _.pickBy(thing, (__, key) => {
    return !_.startsWith(key, 'on');
  });
}

function selectedThingToString(selectedThing: Thing) {
  return JSON.stringify(getEditablePartsOfThing(selectedThing), null, 2);
}
