import { useCallback } from 'react';
import { useKeyDown } from './useKeyUp';
import { Thing } from './types';

export function useKeyboardShortcuts({
  dispatchEditorAppState,
  selectedThingsIndices,
  things,
  onDeleteSelectedThing,
}: {
  dispatchEditorAppState: any;
  selectedThingsIndices: number[];
  things: Array<Thing>;
  onDeleteSelectedThing: (thing: Thing) => void;
}) {
  useKeyDown(
    '≥',
    { altKey: true },
    useCallback(() => {
      const firstSelectedThingIndex =
        selectedThingsIndices.length > 0 ? selectedThingsIndices[0] : null;

      if (
        firstSelectedThingIndex === null ||
        firstSelectedThingIndex === things.length - 1
      ) {
        dispatchEditorAppState({
          type: 'updateSelectedThingsIndices',
          selectedThingsIndices: [0],
        });
        return;
      }

      dispatchEditorAppState({
        type: 'updateSelectedThingsIndices',
        selectedThingsIndices: [firstSelectedThingIndex + 1],
      });
    }, [dispatchEditorAppState, selectedThingsIndices, things.length])
  );

  useKeyDown(
    'Backspace',
    { ctrlOrCommand: true },
    useCallback(() => {
      if (selectedThingsIndices.length !== 1) {
        return;
      }

      onDeleteSelectedThing(things[selectedThingsIndices[0]]);
    }, [onDeleteSelectedThing, selectedThingsIndices, things])
  );
}
