import { Button, Switch, Text } from 'evergreen-ui';
import { useCallback } from 'react';
import {
  GameState,
  PublishState,
  getEditorStorageId,
  getPublishedVersionHash,
  getShareUrl,
  publish,
  unpublish,
  useAppDispatch,
  useAppSelector,
} from './useData';

export function ShareTab() {
  return (
    <div className="flex height-full">
      <Publishing />
    </div>
  );
}

function Publishing() {
  const slug = useAppSelector((state) => state.slug);
  const things = useAppSelector((state) => state.things);
  const playScreenSize = useAppSelector((state) => state.playScreenSize);
  const handlersByType = useAppSelector((state) => state.handlersByType);
  const images = useAppSelector((state) => state.images);
  const publishedVersionHashIfExists = useAppSelector(
    (state) => state.publishedVersionHashIfExists
  );

  const isPublished = publishedVersionHashIfExists !== null;

  const roomId = getEditorStorageId(window);
  const dispatch = useAppDispatch();

  const onPublish = useCallback(() => {
    const gameState: GameState = {
      things,
      playScreenSize,
      handlersByType,
      images,
    };

    const publishState: PublishState = {
      publishedVersionHashIfExists: getPublishedVersionHash(gameState),
      slug,
    };

    fetch('/publish', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        roomId: roomId,
        slug: slug,
        gameState,
        publishState,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return;
        }

        dispatch(publish());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, handlersByType, images, playScreenSize, roomId, slug, things]);

  const onUnpublish = useCallback(() => {
    fetch('/unpublish', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        roomId: roomId,
        slug: slug,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return;
        }

        dispatch(unpublish());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, roomId, slug]);

  const shareUrl = getShareUrl(slug);

  return (
    <div className="flex flex-column" style={{ width: 500 }}>
      <div className="flex justify-between items-center mb1">
        <h1 style={{ margin: 0 }}>Share your game</h1>

        <div className="flex items-center">
          <label className="flex items-center mr2">
            <Switch
              checked={isPublished}
              onClick={() => {
                if (isPublished) {
                  onUnpublish();
                  return;
                }

                onPublish();
              }}
              className="mr1"
            />
            Shared
          </label>

          <Button disabled={!isPublished} onClick={onPublish} size="small">
            Update shared version
          </Button>
        </div>
      </div>

      <div className="flex flex-column">
        <Text size="small" className="mb1">
          {isPublished ? (
            <>
              Share this link to let others play your game on iOS.
              <div className="mt1">
                <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                  {shareUrl}
                </a>
              </div>
            </>
          ) : (
            'Share your game so anyone can play it.'
          )}
        </Text>
      </div>
    </div>
  );
}
