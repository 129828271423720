import { assertTruthy } from './types';
import { useEventListener as useLiveBlocksEventListener } from './useData';
import {
  reportThingSizeChangeToPhysicsEngineFromLocal,
  reportThingVelocityChangeToPhysicsEngineFromLocal,
  updateBodyPositionAfterThingMove,
} from './usePhysicsEngineIfIOs';

export function useLiveBlocksEventListenerIfSimulator({
  physicsEngineIfExists,
  things,
  shouldUseLiveBlocks,
}: {
  physicsEngineIfExists: any;
  things: Array<any>;
  shouldUseLiveBlocks: boolean;
}) {
  const useEventListener = shouldUseLiveBlocks
    ? useLiveBlocksEventListener
    : useNoopEventListener;

  useEventListener(({ event }) => {
    if (event.type === 'thingSizeChange') {
      assertTruthy(physicsEngineIfExists);

      const oldThing = things.find((t) => t.id === event.thingId);
      if (!oldThing) {
        return;
      }

      reportThingSizeChangeToPhysicsEngineFromLocal(
        physicsEngineIfExists,
        oldThing,
        event.newSize
      );

      return;
    }

    if (event.type === 'thingMove') {
      assertTruthy(physicsEngineIfExists);

      updateBodyPositionAfterThingMove(
        physicsEngineIfExists,
        event.thingId,
        event.position,
        event.dimensions
      );
      return;
    }

    // TODO: Would be nicer to update the physics bodies instead of reloading
    if (event.type === 'thingCollisionSettingsChange') {
      window.location.reload();
      return;
    }

    if (event.type === 'thingVelocityChange') {
      assertTruthy(physicsEngineIfExists);

      const oldThing = things.find((t) => t.id === event.thingId);
      if (!oldThing) {
        return;
      }

      reportThingVelocityChangeToPhysicsEngineFromLocal(
        physicsEngineIfExists,
        oldThing,
        event.velocity
      );
      return;
    }
  });
}

function useNoopEventListener() {}
