import _ from 'lodash';
import { createContext, useCallback, useState } from 'react';
import { WatchContext, Watches, WatchLocation } from './types';

export const WatchesContext = createContext({} as WatchContext);

export function useWatchesContext() {
  const [watches, setWatches] = useState<Watches>({});

  const watch = useCallback(
    (location: WatchLocation, color?: string) => {
      const watchId = new Error().stack!.split('\n')[2];

      const watch = { location, color };

      if (watchId in watches && _.isEqual(watches[watchId], watch)) {
        return;
      }

      setTimeout(() => {
        // use a timeout so we can call watch from a render function
        // with state that changes every render and not go into an infinite React
        // state update loop
        setWatches({ ...watches, [watchId]: watch });
      });
    },
    [watches]
  );

  window.watch = watch;

  return { watches, watch };
}

declare global {
  interface Window {
    watch: any;
  }
}

window.watch = window.watch || {};
