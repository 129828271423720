import './App.css';
import {
  Thing,
  Collision,
  assertTruthy,
  ThingEvent,
  ThingUpdate,
  Runtime,
} from './types';
import { useOnCreateThing } from './useOnCreateThing';
import {
  useAppDispatch,
  useAppSelector,
  thingDelete,
  thingUpdate,
  reportGameEngineThingChangesToServer,
  getNewThings,
  updateLastTimeIOsDetected,
} from './useData';
import {
  createPhysicsBodiesForCreatedThings,
  deletePhysicsBodiesForDeletedThings,
  getBodyById,
  updateThingAfterPhysicsBodyChange,
  usePhysicsEngineIfIOs,
} from './usePhysicsEngineIfIOs';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from './useApi';
import { runUpdateThingHandler } from './thingHandlers';
import { ErrorBoundary } from './ErrorBoundary';
import { PlayScreen } from './PlayScreen';
import { useWindowViewportSize } from './useWindowViewportSize';
import { useRequestAnimationFrame } from './useRequestAnimationFrame';
import Matter, { Engine } from 'matter-js';
import { usePreventWholePageScrollingOnMobile } from './preventScrollingOnMobileExceptFor';
import { useIsIOsConnected } from './useIsIOsConnected';
import { useOnDuplicateThing } from './useOnDuplicateThing';
import { useOnFireEvent } from './useOnFireEvent';
import { useLiveBlocksEventListenerIfSimulator } from './useLiveBlocksEventListenerIfSimulator';

export function AppForSimulatorAndPlayer({ runtime }: { runtime: Runtime }) {
  const things = useAppSelector((state) => state.things);
  const lastTimeIOsDetected = useAppSelector(
    (state) => state.lastTimeIOsDetected
  );
  const isPaused = useAppSelector((state) => state.isPaused);
  const fps = useAppSelector((state) => state.fps);
  const handlersByType = useAppSelector((state) => state.handlersByType);

  const dispatch = useAppDispatch();

  const [unprocessedCollisions, setUnprocessedCollisions] = useState<
    Collision[]
  >([]);
  const physicsEngineIfExists = usePhysicsEngineIfIOs({
    initialThings: things,
    isIOs: true,
    onCollisions: (collisions) => {
      setUnprocessedCollisions(unprocessedCollisions.concat(collisions));
    },
  });
  assertTruthy(physicsEngineIfExists);

  const { updateIsIOsConnected } = useIsIOsConnected(
    lastTimeIOsDetected,
    (lastTimeIOsDetected: number) => {
      dispatch(updateLastTimeIOsDetected({ lastTimeIOsDetected }));
    }
  );

  const onCreateThing = useOnCreateThing(things);

  const onDeleteThing = useCallback(
    (thingToDelete: Thing) => {
      dispatch(thingDelete({ idOfThingBeingDeleted: thingToDelete.id }));
    },
    [dispatch]
  );

  const onDuplicateThing = useOnDuplicateThing(things, onCreateThing);

  const onFireEvent = useOnFireEvent({
    dispatch,
    handlersByType,
    physicsEngineIfExists,
  });

  const viewportSize = useWindowViewportSize();

  const api = useApi(
    onCreateThing,
    onDeleteThing,
    onDuplicateThing,
    onFireEvent,
    viewportSize
  );

  useLiveBlocksEventListenerIfSimulator({
    physicsEngineIfExists,
    things,
    shouldUseLiveBlocks: runtime === Runtime.SIMULATOR,
  });

  useEffect(() => {
    createPhysicsBodiesForCreatedThings(things, physicsEngineIfExists);
    deletePhysicsBodiesForDeletedThings(things, physicsEngineIfExists);
  }, [things, physicsEngineIfExists]);

  useRequestAnimationFrame(
    useCallback(() => {
      updateIsIOsConnected(true);

      if (isPaused) {
        return;
      }

      assertTruthy(physicsEngineIfExists);

      // We do this to:
      //
      // * Let us compare things and newThings so we can send to the network
      //   only things that have changed
      // * Create unfrozen versions of 'things' so handlers can mutate them.
      //
      // We don't do this to avoid things being mutated because the objects are
      // frozen. This means the local runner only hears about changes via the
      // network which means updates are laggardly. Could maybe switch to
      // LiveBlocks redux to solve this.
      const newThings = getNewThings(things);

      // Run existing collisions
      unprocessedCollisions.forEach((collision) => {
        const newThing = newThings.find((t) => t.id === collision.bodyId);
        const otherNewThing = newThings.find(
          (t) => t.id === collision.otherBodyId
        );

        // TODO: This is janky but required because a collision might have
        // deleted newThing or otherNewThing. Find better solution.
        if (newThing && otherNewThing) {
          runUpdateThingHandler(
            dispatch,
            physicsEngineIfExists,
            handlersByType,
            newThing,
            ThingEvent.ON_COLLIDE,
            [api, otherNewThing, newThings]
          );
        }

        setUnprocessedCollisions([]);
      });

      for (let i = 0; i < newThings.length; i++) {
        for (let j = i + 1; j < newThings.length; j++) {
          const newThing = newThings[i];
          const newOtherThing = newThings[j];
          if (
            Matter.Collision.collides(
              getBodyById(physicsEngineIfExists, newThing.id),
              getBodyById(physicsEngineIfExists, newOtherThing.id),
              { table: [] }
            ) !== null
          ) {
            runUpdateThingHandler(
              dispatch,
              physicsEngineIfExists,
              handlersByType,
              newThing,
              ThingEvent.ON_COLLIDING,
              [api, newOtherThing, newThings]
            );

            runUpdateThingHandler(
              dispatch,
              physicsEngineIfExists,
              handlersByType,
              newOtherThing,
              ThingEvent.ON_COLLIDING,
              [api, newThing, newThings]
            );
          }
        }
      }

      // Step physics on bodies
      Engine.update(physicsEngineIfExists, 16);

      // Copy physics body changes to newThings (mutates newThings)
      newThings.forEach((newThing: Thing) => {
        updateThingAfterPhysicsBodyChange(physicsEngineIfExists, newThing);
      });

      // Run onTick handlers (which might mutate newThings)
      newThings.forEach((thing) => {
        runUpdateThingHandler(
          dispatch,
          physicsEngineIfExists,
          handlersByType,
          thing,
          ThingEvent.ON_TICK,
          [api, newThings]
        );
      });

      // Copy things changes to server
      newThings.forEach((newThing: Thing, i: number) => {
        reportGameEngineThingChangesToServer(
          things[i],
          newThing,
          i,
          (index: number, update: ThingUpdate) =>
            dispatch(thingUpdate({ index, update }))
        );
      });
    }, [
      updateIsIOsConnected,
      isPaused,
      physicsEngineIfExists,
      things,
      unprocessedCollisions,
      handlersByType,
      api,
      dispatch,
    ]),
    fps
  );

  usePreventWholePageScrollingOnMobile();

  return (
    <ErrorBoundary
      FallbackComponent={(e) => <>Error - {JSON.stringify(e.error)}</>}
    >
      <div
        style={{
          height: viewportSize.height,
          width: viewportSize.width,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <PlayScreen physicsEngine={physicsEngineIfExists} api={api} />
      </div>
    </ErrorBoundary>
  );
}
