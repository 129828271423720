import { getCodeForSelectedThingTypeAndEventType } from './useData';
import { CodeEditor } from './CodeEditor';
import { useCallback, useEffect, useState } from 'react';
import {
  Api,
  CodeEditingSelectedThingTypeAndIfExistsEventType,
  Conversation,
  View,
  assertTruthy,
} from './types';
import { getCodeCodeIfExists } from './prompts';
import { useEditorOnlyStateContext } from './substantiate';
import { mergeHandlerOrThrowIfCodeDoesNotCompile } from './dispatch';
import { useAppSelector } from './useData';
import { HandlerSelectionList } from './HandlerSelectionList';
import { isEqual } from 'lodash';
import { getBoundEventTypesForThingType } from './runCode';
import { Button } from 'evergreen-ui';
import { deleteHandler } from './codeObjectMerge';
import { ConsoleLog } from './ConsoleLog';

export function CodePanel({
  conversation,
  indexOfCodeMessageBeingViewed,
  onRunCode,
  selectedThingTypes,
  api,
  codeView,
}: {
  conversation: Conversation;
  indexOfCodeMessageBeingViewed: number | null;
  onRunCode: (code: string) => void;
  selectedThingTypes: Array<string>;
  api: Api;
  codeView: View;
}) {
  const currentPatchMessageIfExists =
    indexOfCodeMessageBeingViewed !== null
      ? conversation[indexOfCodeMessageBeingViewed]
      : null;

  const currentPatchJustTheCodeIfExists =
    currentPatchMessageIfExists &&
    getCodeCodeIfExists(currentPatchMessageIfExists);

  return (
    <div className="flex flex-column width-full">
      {codeView === View.PATCH ? (
        <pre className="gray-light2 p1">
          {currentPatchJustTheCodeIfExists
            ? codePatchJsonStringToJsonStringWithReadableCode(
                currentPatchJustTheCodeIfExists
              )
            : 'No newly generated code to view'}
        </pre>
      ) : (
        <Code
          onRunCode={onRunCode}
          selectedThingTypes={selectedThingTypes}
          api={api}
        />
      )}
    </div>
  );
}

function Code({
  onRunCode,
  selectedThingTypes,
  api,
}: {
  onRunCode: (code: string) => void;
  selectedThingTypes: Array<string>;
  api: Api;
}) {
  const { editorAppState } = useEditorOnlyStateContext();

  const things = useAppSelector((state) => state.things);
  const { codeEditingSelectedThingTypeAndEventTypeIfExists } = editorAppState;

  const handlersByType = useAppSelector((state) => state.handlersByType);

  const getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse =
    useCallback(() => {
      const isStoredThingTypeSelected =
        codeEditingSelectedThingTypeAndEventTypeIfExists &&
        selectedThingTypes.includes(
          codeEditingSelectedThingTypeAndEventTypeIfExists.thingType
        );

      const thingTypeToUse = isStoredThingTypeSelected
        ? codeEditingSelectedThingTypeAndEventTypeIfExists.thingType
        : selectedThingTypes[0];

      const eventTypeToUse =
        getBoundEventTypesForThingType(thingTypeToUse, handlersByType).length >
        0
          ? getBoundEventTypesForThingType(thingTypeToUse, handlersByType)[0]
          : null;

      const codeEditingSelectedThingTypeAndEventTypeIfExistsToUse: CodeEditingSelectedThingTypeAndIfExistsEventType =
        {
          thingType: thingTypeToUse,
          eventType: isStoredThingTypeSelected
            ? codeEditingSelectedThingTypeAndEventTypeIfExists.eventType
            : eventTypeToUse,
        };

      return codeEditingSelectedThingTypeAndEventTypeIfExistsToUse;
    }, [
      codeEditingSelectedThingTypeAndEventTypeIfExists,
      handlersByType,
      selectedThingTypes,
    ]);

  const codeEditingSelectedThingTypeAndEventTypeIfExistsToUse =
    getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse();

  const [
    editedCodeEditingSelectedThingTypeAndEventTypeToUse,
    setEditedCodeEditingSelectedThingTypeAndEventTypeToUse,
  ] = useState<CodeEditingSelectedThingTypeAndIfExistsEventType>(
    getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse()
  );

  return (
    <div className="flex flex-column height-full">
      <div className="flex justify-between items-center pb1">
        <HandlerSelectionList
          things={things.filter((t) => selectedThingTypes.includes(t.type))}
          codeEditingSelectedThingTypeAndEventTypeIfExistsToUse={
            codeEditingSelectedThingTypeAndEventTypeIfExistsToUse
          }
          onRunCode={onRunCode}
          api={api}
        />
      </div>

      <div className="flex flex-column height-full">
        {/* Very weird bug where Monaco takes up more than half 
        (created by flex 1 here and flex 1 below) of available space.  
        May be because Monaco renders a bit bigger after the its 
        flex: 1 container's initial render.  Can't crack the problem, 
        so for now the last few log lines are off the bottom of 
        the screen which is probably fine */}
        <div style={{ flex: 1 }}>
          <Editor
            codeEditingSelectedThingTypeAndEventTypeIfExistsToUse={
              codeEditingSelectedThingTypeAndEventTypeIfExistsToUse
            }
            editedCodeEditingSelectedThingTypeAndEventTypeToUse={
              editedCodeEditingSelectedThingTypeAndEventTypeToUse
            }
            setEditedCodeEditingSelectedThingTypeAndEventTypeToUse={
              setEditedCodeEditingSelectedThingTypeAndEventTypeToUse
            }
            getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse={
              getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse
            }
            onRunCode={onRunCode}
          />
        </div>

        <div style={{ flex: 1 }} className="overflow-hidden">
          <ConsoleLog />
        </div>
      </div>
    </div>
  );
}

function Editor({
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
  editedCodeEditingSelectedThingTypeAndEventTypeToUse,
  setEditedCodeEditingSelectedThingTypeAndEventTypeToUse,
  getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
  onRunCode,
}: {
  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse: CodeEditingSelectedThingTypeAndIfExistsEventType;
  editedCodeEditingSelectedThingTypeAndEventTypeToUse: CodeEditingSelectedThingTypeAndIfExistsEventType;
  setEditedCodeEditingSelectedThingTypeAndEventTypeToUse: (
    codeEditingSelectedThingTypeAndEventType: CodeEditingSelectedThingTypeAndIfExistsEventType
  ) => void;
  getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse: () => CodeEditingSelectedThingTypeAndIfExistsEventType;
  onRunCode: (code: string) => void;
}) {
  const { editorAppState, dispatchEditorAppState } =
    useEditorOnlyStateContext();

  const { code } = editorAppState;

  const codeToShow = getCodeForSelectedThingTypeAndEventType(
    code,
    codeEditingSelectedThingTypeAndEventTypeIfExistsToUse
  );

  useEffect(() => {
    if (
      isEqual(
        codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
        editedCodeEditingSelectedThingTypeAndEventTypeToUse
      )
    ) {
      return;
    }

    setPossiblyInvalidCodeIfExists(codeToShow);

    setEditedCodeEditingSelectedThingTypeAndEventTypeToUse(
      getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse()
    );
  }, [
    codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
    codeToShow,
    editedCodeEditingSelectedThingTypeAndEventTypeToUse,
    getCodeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
    setEditedCodeEditingSelectedThingTypeAndEventTypeToUse,
  ]);

  const [possiblyInvalidCodeIfExists, setPossiblyInvalidCodeIfExists] =
    useState<string | null>(codeToShow);

  const [hasParseError, setHasParseError] = useState(false);

  const onDeleteHandler = useCallback(() => {
    assertTruthy(
      codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.eventType
    );

    const mergedCode = deleteHandler(
      code,
      codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.thingType,
      codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.eventType
    );

    dispatchEditorAppState({
      type: 'updateCode',
      code: mergedCode,
    });

    onRunCode(mergedCode);

    setEditedCodeEditingSelectedThingTypeAndEventTypeToUse({
      thingType:
        codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.thingType,
      eventType: null,
    });
  }, [
    code,
    codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.eventType,
    codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.thingType,
    dispatchEditorAppState,
    onRunCode,
    setEditedCodeEditingSelectedThingTypeAndEventTypeToUse,
  ]);

  if (possiblyInvalidCodeIfExists === null) {
    return null;
  }

  return (
    <div className="height-full relative">
      <CodeEditor
        value={possiblyInvalidCodeIfExists}
        language="typescript"
        onChange={(newCode: string) => {
          if (
            !isEqual(
              codeEditingSelectedThingTypeAndEventTypeIfExistsToUse,
              editedCodeEditingSelectedThingTypeAndEventTypeToUse
            )
          ) {
            return;
          }

          try {
            assertTruthy(
              codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.eventType
            );

            const mergedCode = mergeHandlerOrThrowIfCodeDoesNotCompile(
              code,
              newCode,
              {
                type: 'textEdit',
                thingType:
                  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.thingType,
                eventType:
                  codeEditingSelectedThingTypeAndEventTypeIfExistsToUse.eventType,
              }
            );

            dispatchEditorAppState({
              type: 'updateCode',
              code: mergedCode,
            });

            setPossiblyInvalidCodeIfExists(newCode);
            setHasParseError(false);

            onRunCode(mergedCode);
          } catch (e) {
            setHasParseError(true);
            setPossiblyInvalidCodeIfExists(newCode);
          }
        }}
        hasError={hasParseError}
      />

      <div style={{ position: 'absolute', top: 0, right: 0 }} className="p1">
        <Button
          size="small"
          className="ml1"
          intent="danger"
          onClick={() => {
            const shouldDelete = window.confirm(
              'Are you sure you want to delete this handler?'
            );

            if (!shouldDelete) {
              return;
            }

            onDeleteHandler();
          }}
        >
          Delete handler
        </Button>
      </div>
    </div>
  );
}

function codePatchJsonStringToJsonStringWithReadableCode(justTheCode: string) {
  const readableCode = justTheCode.replace(/\\n/g, '\n    ');

  return readableCode;
}
