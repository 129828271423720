import './App.css';
import {
  Thing,
  Tab,
  Api,
  View,
  EditorOnlyState,
  assertTruthy,
  ThingEvent,
} from './types';
import { Toolbar } from './Toolbar';
import { useWatchesContext, WatchesContext } from './useWatchesContext';
import { fixResizeObserverLoopLimitExceededInMonaco } from './fixResizeObserverLoopLimitExceededInMonaco';
import { useOnCreateThing } from './useOnCreateThing';
import { ThingTab } from './ThingTab';
import { ShareTab } from './ShareTab';
import { SpecTab } from './SpecTab';
import { CodeTab } from './CodeTab';
import { HelpTab } from './HelpTab';
import { getEditorOnlyStateContext, useEditorOnlyState } from './substantiate';
import { EditorOnlyStateAction, _dispatch } from './dispatch';
import {
  useAppDispatch,
  useAppSelector,
  thingDelete,
  thingUpdate,
  handlersByTypeUpdate,
  handlersByTypeClear,
  updateLastTimeIOsDetected,
  getSelectedThings,
  logClear,
} from './useData';
import { EditorScreen } from './EditorScreen';
import {
  reportThingMoveToPhysicsEngineFromRemote,
  reportThingVelocityChangeToPhysicsEngineFromRemote,
} from './usePhysicsEngineIfIOs';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from './useApi';
import { ClickableTabs } from './ClickableTabs';
import { runCode } from './runCode';
import { useIsIOsConnected } from './useIsIOsConnected';
import { useOnDuplicateThing } from './useOnDuplicateThing';
import { useOnFireEvent } from './useOnFireEvent';
import { SettingsButtonAndModal } from './SettingsButtonAndModal';
import { useKeyboardShortcuts } from './useKeyboardShortcuts';

fixResizeObserverLoopLimitExceededInMonaco();

export function AppForEditor({
  loadedEditorOnlyState,
}: {
  loadedEditorOnlyState: EditorOnlyState;
}) {
  const playScreenSize = useAppSelector((state) => state.playScreenSize);
  const things = useAppSelector((state) => state.things);
  const lastTimeIOsDetected = useAppSelector(
    (state) => state.lastTimeIOsDetected
  );
  const handlersByType = useAppSelector((state) => state.handlersByType);

  const dispatch = useAppDispatch();

  const AppContext = getEditorOnlyStateContext<
    EditorOnlyState,
    EditorOnlyStateAction
  >();
  const { editorAppState, dispatchEditorAppState } = useEditorOnlyState(
    _dispatch,
    loadedEditorOnlyState
  );
  const { watches, watch } = useWatchesContext();

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const physicsEngineIfExists = null;

  const { selectedTab, selectedThingsIndices } = editorAppState;

  const { isIOsConnected } = useIsIOsConnected(
    lastTimeIOsDetected,
    (lastTimeIOsDetected: number) => {
      dispatch(updateLastTimeIOsDetected({ lastTimeIOsDetected }));
    }
  );

  const onCreateThing = useOnCreateThing(things);

  const onDeleteThing = useCallback(
    (thingToDelete: Thing) => {
      dispatch(thingDelete({ idOfThingBeingDeleted: thingToDelete.id }));
      dispatchEditorAppState({ type: 'clearSelectedThingsIndices' });
    },
    [dispatch, dispatchEditorAppState]
  );

  const onDuplicateThing = useOnDuplicateThing(things, onCreateThing);

  const onFireEvent = useOnFireEvent({
    dispatch,
    handlersByType,
    physicsEngineIfExists,
  });

  const api = useApi(
    onCreateThing,
    onDeleteThing,
    onDuplicateThing,
    onFireEvent,
    playScreenSize
  );

  const [codeView, setCodeView] = useState<View>(View.FULL);

  const selectedThings = getSelectedThings(things, selectedThingsIndices);

  const onDeleteSelectedThing = useCallback(() => {
    assertTruthy(selectedThings.length === 1);
    dispatch(thingDelete({ idOfThingBeingDeleted: selectedThings[0].id }));
    dispatchEditorAppState({ type: 'clearSelectedThingsIndices' });
  }, [selectedThings, dispatch, dispatchEditorAppState]);

  const onDuplicateSelectedThing = useCallback(() => {
    assertTruthy(selectedThings.length === 1);
    onDuplicateThing(selectedThings[0]);
  }, [onDuplicateThing, selectedThings]);

  const onBringOnScreenSelectedThing = useCallback(() => {
    assertTruthy(selectedThings.length === 1);
    const selectedThingIndex = selectedThingsIndices[0];
    const selectedThing = selectedThings[0];

    const newPositionAndVelocity = {
      x: playScreenSize.width / 2 - selectedThing.width / 2,
      y: playScreenSize.height / 2 - selectedThing.height / 2,
      xVelocity: 0,
      yVelocity: 0,
    };

    reportThingMoveToPhysicsEngineFromRemote(
      selectedThing,
      newPositionAndVelocity
    );
    reportThingVelocityChangeToPhysicsEngineFromRemote(
      selectedThing,
      newPositionAndVelocity
    );

    dispatch(
      thingUpdate({ index: selectedThingIndex, update: newPositionAndVelocity })
    );
  }, [
    dispatch,
    playScreenSize.height,
    playScreenSize.width,
    selectedThings,
    selectedThingsIndices,
  ]);

  const onRunCode = useCallback(
    // Have to pass code as with the other function calls like this that rely on
    // latest state because LiveBlocks doesn't have store state locally until
    // there's a network roundtrip
    (code: string) => {
      dispatchEditorAppState({
        type: 'updateCodeAtTimeOfLastRun',
        value: code,
      });
      runCode(
        code,
        () => dispatch(handlersByTypeClear()),
        (
          type: string,
          eventName: ThingEvent,
          update: {
            code?: string | undefined;
            prompt?: string | undefined;
          }
        ) => dispatch(handlersByTypeUpdate({ type, eventName, update })),
        things
      );
    },
    [dispatch, dispatchEditorAppState, things]
  );

  useKeyboardShortcuts({
    dispatchEditorAppState,
    selectedThingsIndices,
    things,
    onDeleteSelectedThing,
  });

  useEffect(() => {
    dispatch(logClear());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppContext.Provider value={{ editorAppState, dispatchEditorAppState }}>
      <WatchesContext.Provider value={{ watches, watch }}>
        <div className="flex items-stretch pl1 pt1 height-fill border-box">
          <div
            className="flex flex-column"
            style={{ width: playScreenSize.width }}
          >
            <div className="flex items-center mb1" style={{ height: 36 }}>
              <Toolbar
                isIOsConnected={isIOsConnected}
                onCreateThing={onCreateThing}
              />
            </div>

            <div className="border border-gray-light rounded">
              <EditorScreen
                physicsEngineIfExists={physicsEngineIfExists}
                playScreenSize={playScreenSize}
                api={api}
              />
            </div>
          </div>

          <div className="flex flex-column mx1 border-box width-full overflow-hidden">
            <div className="flex justify-between items-center mr1">
              <ClickableTabs selectedTab={selectedTab} />

              <SettingsButtonAndModal
                isSettingsModalOpen={isSettingsModalOpen}
                setIsSettingsModalOpen={setIsSettingsModalOpen}
              />
            </div>

            <div
              className="rounded border-box p1"
              style={{
                height: 'calc(100vh - 44px - 8px)',
                backgroundColor: '#EBF0FF',
              }} // 44 for tabs, 8 for top padding
            >
              <SelectedTab
                selectedThings={selectedThings}
                onDeleteSelectedThing={onDeleteSelectedThing}
                onBringOnScreen={onBringOnScreenSelectedThing}
                onDuplicateSelectedThing={onDuplicateSelectedThing}
                api={api}
                onRunCode={onRunCode}
                selectedTab={selectedTab}
                selectedThingTypes={selectedThings.map((t) => t.type)}
                codeView={codeView}
                setCodeView={setCodeView}
                onOpenSettings={() => setIsSettingsModalOpen(true)}
              />
            </div>
          </div>
        </div>
      </WatchesContext.Provider>
    </AppContext.Provider>
  );
}

function SelectedTab({
  selectedThings,
  onDeleteSelectedThing,
  onBringOnScreen,
  onDuplicateSelectedThing,
  api,
  onRunCode,
  selectedTab,
  selectedThingTypes,
  codeView,
  setCodeView,
  onOpenSettings,
}: {
  selectedThings: Array<Thing>;
  onDeleteSelectedThing: () => void;
  onBringOnScreen: () => void;
  onDuplicateSelectedThing: () => void;
  api: Api;
  onRunCode: (code: string) => void;
  selectedTab: Tab;
  selectedThingTypes: Array<string>;
  codeView: View;
  setCodeView: (view: View) => void;
  onOpenSettings: () => void;
}) {
  switch (selectedTab) {
    case Tab.THING:
      return (
        <ThingTab
          selectedThings={selectedThings}
          onDeleteSelectedThing={onDeleteSelectedThing}
          onBringOnScreen={onBringOnScreen}
          onDuplicateSelectedThing={onDuplicateSelectedThing}
          api={api}
        />
      );
    case Tab.SPEC:
      return (
        <SpecTab
          onRunCode={onRunCode}
          selectedThingTypes={selectedThings.map((t) => t.type)}
          api={api}
          codeView={codeView}
          setCodeView={setCodeView}
          onOpenSettings={onOpenSettings}
        />
      );
    case Tab.CODE:
      return (
        <CodeTab
          onRunCode={onRunCode}
          selectedThingTypes={selectedThingTypes}
          api={api}
          codeView={codeView}
        />
      );
    case Tab.SHARE:
      return <ShareTab />;
    case Tab.HELP:
      return <HelpTab onOpenSettings={onOpenSettings} />;
  }
}
