import { useEffect, useRef } from 'react';

export function useRequestAnimationFrame(
  onRequestAnimationFrame: () => void,
  fps: number
) {
  const lastCallRef = useRef(0);

  useEffect(() => {
    let isThisLoopRunning = true;

    (function tick() {
      if (!isThisLoopRunning) {
        return;
      }

      const now = Date.now();
      const nextTick = lastCallRef.current + 1000 / fps;

      if (nextTick > now) {
        requestAnimationFrame(tick);
        return;
      }

      lastCallRef.current = now;
      onRequestAnimationFrame();

      requestAnimationFrame(tick);
    })();

    return () => {
      isThisLoopRunning = false;
    };
  }, [onRequestAnimationFrame, fps]);
}
