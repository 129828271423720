import { Tab } from './types';
import { capitalize } from 'lodash';
import { getTabIcon } from './ClickableTabs';
import { useEditorOnlyStateContext } from './substantiate';
import { Text } from 'evergreen-ui';

export function HelpTab({ onOpenSettings }: { onOpenSettings: () => void }) {
  return (
    <div className="height-full overflow-scroll">
      <div style={{ width: 500 }}>
        <h1 style={{ marginTop: 0 }}>Help</h1>

        <h2>Table of contents</h2>

        <ul>
          <li>
            <a href="#quick-start">Quick start</a>
          </li>
          <li>
            <a href="#events-that-things-can-respond-to">
              Events that things can respond to
            </a>
          </li>
          <li>
            <a href="#thing-properties">Thing properties</a>
          </li>
          <li>
            <a href="#api">API</a>
          </li>
        </ul>

        <h2 id="quick-start">Quick start</h2>

        <ol>
          <Text>
            <li>Add a thing by clicking the ➕ Add thing button.</li>
          </Text>

          <Text>
            <li>
              On your phone, scan the QR code on the left to run the game. (If
              you can't see a QR code, click the phone icon in the top bar.) You
              should see the new thing you just created.
            </li>
          </Text>

          <Text>
            <li>
              Go to the <TabLink tab={Tab.THING} /> tab, click on the new thing
              to select it and change its <code>type</code> to <code>Buzz</code>
              .
            </li>
          </Text>

          <Text>
            <li>
              Open the{' '}
              <span className="pointer text-blue" onClick={onOpenSettings}>
                ⚙️ Settings
              </span>{' '}
              and add your OpenAI API key.
            </li>
          </Text>

          <Text>
            <li>
              Go to the <TabLink tab={Tab.SPEC} /> tab, make sure Buzz is
              selected and enter into the text field:
              <p>
                <code>
                  On world tap, moves gradually towards the location of the tap.
                </code>
              </p>
            </li>
          </Text>

          <Text>
            <li>
              Hit <code>⌘-Return</code> or click Generate Code.
            </li>
          </Text>

          <Text>
            <li>Press the ▶ Play button above.</li>
          </Text>

          <Text>
            <li>Try out your new game!</li>
          </Text>
        </ol>

        <h2 id="events-that-things-can-respond-to">
          Events that things can respond to
        </h2>

        <ul>
          {Object.keys(events).map((event) => (
            <Text key={event}>
              <li>
                <span className="stronger">{event}</span> -{' '}
                {events[event].description}
                <br />
                <code>{events[event].signature}</code>
              </li>
            </Text>
          ))}
        </ul>

        <h2 id="thing-properties">Thing properties</h2>

        <ul>
          {Object.keys(thingProperties).map((property) => (
            <Text key={property}>
              <li>
                <span className="stronger">{property}</span>{' '}
                {thingProperties[property].type}
                <br />
                {thingProperties[property].description}
              </li>
            </Text>
          ))}
        </ul>

        <h2 id="api">API</h2>

        <Text>
          A set of helper functions. The <code>api</code> object containing
          these functions is passed into every event handler.
        </Text>

        <ul>
          {Object.keys(apiFunctions).map((apiFunction) => (
            <Text key={apiFunction}>
              <li>
                <span className="stronger">{apiFunction}</span> -{' '}
                {apiFunctions[apiFunction].description}
                <br />
                <code>
                  <pre>{apiFunctions[apiFunction].signature}</pre>
                </code>
              </li>
            </Text>
          ))}
        </ul>
      </div>
      <hr className="mt2" />
    </div>
  );
}

function TabLink({ tab }: { tab: Tab }) {
  const { dispatchEditorAppState } = useEditorOnlyStateContext();

  return (
    <span
      className="pointer text-blue"
      onClick={() =>
        dispatchEditorAppState({ type: 'updateSelectedTab', selectedTab: tab })
      }
    >
      {getTabIcon(tab)} {capitalize(tab)}
    </span>
  );
}

const events: {
  [eventName: string]: { description: string; signature: string };
} = {
  Tick: {
    description: 'Called once per frame (around 60 times a second).',
    signature: `function onTick(
  this: Thing,
  api: Api,
  things: Array<Thing>,
): void`,
  },
  'World tap': {
    description: 'Called when the player taps anywhere except on a thing.',
    signature: `function onWorldTap(
  this: Thing,
  api: Api,
  coordinates: Coordinates,
  things: Array<Thing>,
): void`,
  },
  'Thing tap': {
    description: 'Called when the player taps on an object.',
    signature: `function onThingTap(
  this: Thing,
  api: Api,
  things: Array<Thing>,
): void`,
  },
  Collide: {
    description: 'Called when a thing starts colliding with another thing.',
    signature: `function onCollide(
  this: Thing,
  api: Api,
  otherThing: Thing,
  things: Array<Thing>,
): void`,
  },
  Colliding: {
    description:
      'Called for every tick that a thing is colliding with another thing.',
    signature: `function onColliding(
  this: Thing,
  api: Api,
  otherThing: Thing,
  things: Array<Thing>,
): void`,
  },
  Uncollide: {
    description: 'Called when a thing stops colliding with another thing',
    signature: `function onUncollide(
  this: Thing,
  api: Api,
): void`,
  },
  'Custom event': {
    description: 'Called when a thing has a custom event fired on it.',
    signature: `function onEvent(
  this: Thing,
  api: Api,
  event: string,
  data: { [key: string]: any },
  things: Array<Thing>,
): void`,
  },
};

const thingProperties: {
  [property: string]: { type: string; description: string };
} = {
  x: { type: 'number', description: 'left of the thing' },
  y: { type: 'number', description: 'top of the thing' },
  width: { type: 'number', description: 'width of the thing' },
  height: { type: 'number', description: 'height of the thing' },
  xVelocity: {
    type: 'number',
    description:
      'velocity in the x direction; automatically applied every tick',
  },
  yVelocity: {
    type: 'number',
    description:
      'velocity in the y direction; automatically applied every tick',
  },
  color: { type: 'string', description: 'any hex color; include the #' },
  textColor: { type: 'string', description: 'any hex color; include the #' },
  canMove: {
    type: 'boolean',
    description:
      'if false: xVelocity/yVelocity ignored, is unmoved by collisions, collisions not reported',
  },
  canCollide: {
    type: 'boolean',
    description:
      'if false: other objects pass through it, collisions not reported',
  },
  type: {
    type: 'string',
    description:
      'a word/phrase that describes what type of thing this is; all things with the same type share the same handlers',
  },
  text: {
    type: 'string?',
    description:
      'if specified and onDraw unchanged, thing is rendered as this text',
  },
  angle: { type: 'number', description: 'angle of the thing in degrees' },
  isHidden: { type: 'boolean', description: 'if true, is not drawn on phone' },
  id: {
    type: 'boolean',
    description: 'unique ID of this object; do not change this',
  },
  parentId: { type: 'number | null', description: 'ID of parent object' },
};

const apiFunctions: {
  [functionName: string]: {
    description: string;
    signature: string;
  };
} = {
  _: {
    description: 'Lodash',
    signature: `e.g. _.merge({ a: 1 }, { b: 2 })`,
  },
  vectorBetween: {
    description: 'Returns the vector between two points.',
    signature: `function vectorBetween(
  startPoint: {x: number, y: number}, 
  endPoint: {x: number, y: number}
): {x: number, y: number}`,
  },
  unitVector: {
    description: 'Returns the unit vector of the passed vector.',
    signature: `function unitVector(
  vector: {x: number, y: number}, 
  ): {x: number, y: number}`,
  },
  magnitude: {
    description: 'Returns the magnitude of the passed vector.',
    signature: `function magnitude(
  vector: {x: number, y: number}, 
): number`,
  },
  rect: {
    description:
      'Returns an object with the main dimensions of the passed thing',
    signature: `function rect(
  thing: Thing,
): {
  x: number,
  y: number,
  width: number,
  height: number,
  top: number,
  bottom: number,
  left: number,
  right: number,
  angle: number,
): number`,
  },
  createThing: {
    description: 'Creates a new thing with the passed properties',
    signature: `function createThing(
  properties: Thing,
): Thing`,
  },
  deleteThing: {
    description: 'Deletes the passed thing',
    signature: `function deleteThing(
  thing: Thing,
): void`,
  },
  duplicateThing: {
    description: 'Duplicates the passed thing',
    signature: `function duplicateThing(
  thing: Thing,
): Thing`,
  },
  fireEvent: {
    description: 'Fires an event on the passed thing',
    signature: `function fireEvent(
  thing: Thing,
  things: Array<Thing>,
  event: string,
  data: { [key: string]: any },
): void`,
  },
  getParent: {
    description: 'Gets the parent of the passed thing, if it has one',
    signature: `function getParent(
  thing: Thing,
): Thing | null`,
  },
  getScreenSize: {
    description: 'Gets the size of the screen',
    signature: `function getScreenSize(): { width: number, height: number }`,
  },
};
