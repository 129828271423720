import { parseCodeObject } from './codeObjectMerge';
import { HandlerByEventName, HandlersByType, Thing, ThingEvent } from './types';

export function runCode(
  code: string,
  handlersByTypeClear: () => void,
  handlersByTypeUpdate: (
    type: string,
    eventName: ThingEvent,
    update: {
      code?: string | undefined;
      prompt?: string | undefined;
    }
  ) => void,
  things: Array<Thing>
) {
  const json = parseCodeObject(code);

  handlersByTypeClear();

  Object.keys(json).forEach((thingType: string) => {
    Object.keys(json[thingType]).forEach((eventName) => {
      handlersByTypeUpdate(thingType, eventName as ThingEvent, {
        code: json[thingType][eventName],
      });
    });
  });
}

export function getHandlersByTypeFromCode(code: string): HandlersByType {
  const json = parseCodeObject(code);

  const handlersByType = Object.keys(json).reduce(
    (handlersByType: HandlersByType, thingType: string) => {
      return {
        ...handlersByType,
        [thingType]: Object.keys(json[thingType]).reduce(
          (handlerByEventName: HandlerByEventName, eventName) => {
            return {
              ...handlerByEventName,
              [eventName as ThingEvent]: {
                code: json[thingType][eventName],
              },
            };
          },
          {}
        ),
      };
    },
    {}
  );

  return handlersByType;
}

export function getBoundEventTypesForThingType(
  thingType: string,
  handlersByType: HandlersByType
): Array<ThingEvent> {
  const eventTypes = Object.values(ThingEvent).filter((eventType) => {
    return handlersByType[thingType]?.[eventType] !== undefined;
  });

  return eventTypes;
}
