import { useContext } from 'react';
import { WatchesContext } from './useWatchesContext';

export function Watches() {
  const { watches } = useContext(WatchesContext);

  return (
    <>
      {Object.values(watches).map((watch, i) => {
        const border = `2px solid ${watch.color || 'red'}`;
        // coordinates
        if ('x' in watch.location && 'y' in watch.location) {
          return (
            <div
              key={i}
              className="rounded"
              style={{
                position: 'absolute',
                left: watch.location.x,
                top: watch.location.y,
                width: 'width' in watch.location ? watch.location.width : 4,
                height: 'height' in watch.location ? watch.location.height : 4,
                border,
              }}
            >
              &nbsp;
            </div>
          );
        }

        // just x or y
        return (
          <div
            key={i}
            style={{
              position: 'absolute',
              left: 'x' in watch.location ? watch.location.x : 0,
              top: 'x' in watch.location ? 0 : watch.location.y,
              width: 'x' in watch.location ? 0 : 1000,
              height: 'x' in watch.location ? 1000 : 0,
              border,
            }}
          >
            &nbsp;
          </div>
        );
      })}
    </>
  );
}
