import { useCallback, useEffect } from 'react';
import { RunningGame } from './RunningGame';
import { runUpdateThingHandler } from './thingHandlers';
import { Api, ThingEvent, Thing } from './types';
import {
  getNewThings,
  thingUpdate,
  updatePlayScreenSize,
  useAppDispatch,
  useAppSelector,
} from './useData';
import { Engine } from 'matter-js';
import { useWindowViewportSize } from './useWindowViewportSize';

export function PlayScreen({
  physicsEngine,
  api,
}: {
  physicsEngine: Engine;
  api: Api;
}) {
  // stop scrolling on touch drag
  useEffect(() => document.body.setAttribute('style', 'overflow: hidden'));

  const things = useAppSelector((state) => state.things);
  const isPaused = useAppSelector((state) => state.isPaused);
  const handlersByType = useAppSelector((state) => state.handlersByType);
  const dispatch = useAppDispatch();

  const playScreenSize = useWindowViewportSize();
  useEffect(() => {
    dispatch(updatePlayScreenSize({ playScreenSize }));
  }, [dispatch, playScreenSize]);

  const onGlobalTouch = useCallback(
    (coordinates) => {
      const newThings = getNewThings(things);

      newThings.forEach((newThing: Thing, i: number) => {
        // no need to offset because we render the playscreen in the far top
        // right of the phone

        // TODO: Send updates for changes to `newThings`

        runUpdateThingHandler(
          dispatch,
          physicsEngine,
          handlersByType,
          newThing,
          ThingEvent.ON_WORLD_TAP,
          [api, coordinates, things]
        );

        dispatch(thingUpdate({ index: i, update: newThing }));
      });
    },
    [api, dispatch, handlersByType, physicsEngine, things]
  );

  return (
    <div
      className="no-user-select"
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        overflow: 'hidden',
      }}
      onTouchStart={(e) => {
        onGlobalTouch({ x: e.touches[0].pageX, y: e.touches[0].pageY });
      }}
    >
      <RunningGame
        physicsEngineIfExists={physicsEngine}
        screenGlobalTopLeft={null}
        api={api}
      />

      {isPaused && (
        <div
          className="flex justify-end items-start strongest text-red pt1 pr1 border-box"
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            fontSize: 20,
          }}
        >
          Paused
        </div>
      )}
    </div>
  );
}
