import { useCallback, useEffect, MouseEvent } from 'react';

export function useMouseMoveListener(
  onMouseMoveCallback: (e: MouseEvent) => void
) {
  const onMouseMove = useCallback(
    (e) => {
      onMouseMoveCallback(e);
    },
    [onMouseMoveCallback]
  );

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);
}
