import { useCallback, useEffect, useState } from 'react';

export function useIsIOsConnected(
  lastTimeIOsDetected: number,
  updateLastTimeIOsDetected: (lastTimeIOsDetected: number) => void
) {
  // Run by phone to update lastTimeIOsDetected
  const updateIsIOsConnected = useCallback(
    (isIOs: boolean) => {
      if (isIOs && Date.now() - lastTimeIOsDetected > 500) {
        updateLastTimeIOsDetected(Date.now());
      }
    },
    [lastTimeIOsDetected, updateLastTimeIOsDetected]
  );

  // Run by computer
  const calculateIsIOsConnected = useCallback((lastTimeIOsDetected: number) => {
    return (
      Date.now() - lastTimeIOsDetected <
      // Check within 1500 (not 1000) because if running at 1fps we only update lastTimeIOsDetected every 1000ms
      1500
    );
  }, []);

  const [isIOsConnected, setIsIOsConnected] = useState<boolean>(
    calculateIsIOsConnected(lastTimeIOsDetected)
  );

  // Run by computer to recalculate if iOS is connected
  useEffect(() => {
    setIsIOsConnected(calculateIsIOsConnected(lastTimeIOsDetected));
    const intervalId = setInterval(() => {
      setIsIOsConnected(calculateIsIOsConnected(lastTimeIOsDetected));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [calculateIsIOsConnected, lastTimeIOsDetected]);

  return { isIOsConnected, updateIsIOsConnected };
}
