import classNames from 'classnames';
import { useAppSelector } from './useData';
import moment from 'moment';
import { useEffect } from 'react';
import { noop } from 'lodash';

export function ConsoleLog() {
  let logLines = useAppSelector((state) => state.logLines);
  logLines = logLines.concat();

  useEffect(() => {
    const interval = setInterval(noop, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mt1 p1 white height-full">
      <h2 style={{ marginTop: 0 }}>Debug log</h2>

      <div
        style={{ fontSize: 12 }}
        className="height-full width-full overflow-scroll"
      >
        {logLines
          .concat()
          .reverse()
          .map((logLine, i) => (
            <div
              key={i}
              className={classNames(
                'flex justify-between width-full monospace border-box',
                {
                  'border-top border-light-gray': i > 0,
                }
              )}
              style={{ padding: '3px 0 3px 0' }}
            >
              <div>
                {logLine.type === 'error' ? '⛔️' : '＜'}{' '}
                {logLine.args.map((arg) => getPrettyConsoleArg(arg)).join(', ')}
              </div>

              <div className="flex text-light-gray">
                <div>{logLine.thingType}</div>,{' '}
                <div className="ml1">{logLine.eventType}</div>,{' '}
                <div className="ml1">{moment(logLine.date).fromNow()}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

function getPrettyConsoleArg(arg: unknown) {
  if (typeof arg === 'object' || Array.isArray(arg)) {
    return JSON.stringify(arg);
  }

  return arg;
}
