import React from 'react';
import { isIOs } from './isIOs';
import { Coordinates, Thing, assertTruthy, Api } from './types';
import { ThingWrapperForPlayScreen } from './ThingWrapperForPlayScreen';
import { ThingWrapperForEditorScreen } from './ThingWrapperForEditorScreen';
import { Engine } from 'matter-js';

export function ThingWrapperForEditorScreenOrPlayScreen({
  physicsEngineIfExists,
  children,
  thing,
  thingIndex,
  things,
  screenGlobalTopLeft,
  api,
}: {
  physicsEngineIfExists: Engine | null;
  children: React.ReactNode;
  thing: Thing;
  thingIndex: number;
  things: Array<Thing>;
  screenGlobalTopLeft: Coordinates | null;
  api: Api;
}) {
  if (isIOs()) {
    assertTruthy(physicsEngineIfExists);

    return (
      <ThingWrapperForPlayScreen
        physicsEngine={physicsEngineIfExists}
        thingIndex={thingIndex}
        things={things}
        api={api}
      >
        {children}
      </ThingWrapperForPlayScreen>
    );
  }

  assertTruthy(screenGlobalTopLeft);

  return (
    <ThingWrapperForEditorScreen
      thingIndex={thingIndex}
      thing={thing}
      screenGlobalTopLeft={screenGlobalTopLeft}
    >
      {children}
    </ThingWrapperForEditorScreen>
  );
}
