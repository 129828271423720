import { isEqual } from 'lodash';
import { mergeCode } from './codeObjectMerge';
import { compileHandlerCodeToFunction } from './thingHandlers';
import {
  Artifact,
  CodeEditingSelectedThingTypeAndEventType,
  CodeMerge,
  CodeMergeTextEdit,
  DrawingTool,
  EditorOnlyState,
  HandlerByEventName,
  HandlersByType,
  Message,
  SpecEntry,
  Tab,
  ThingEvent,
  assertTruthy,
  exhaustiveGuard,
} from './types';

export function _dispatch(
  state: EditorOnlyState,
  action: EditorOnlyStateAction
): EditorOnlyState {
  switch (action.type) {
    case 'updateSelectedThingsIndices':
      return {
        ...state,
        selectedThingsIndices: action.selectedThingsIndices,
      };
    case 'clearSelectedThingsIndices':
      return {
        ...state,
        selectedThingsIndices: [],
      };
    case 'updateSelectedTab':
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    case 'updateConversationInput':
      return {
        ...state,
        conversationInput: action.conversationInput,
      };
    case 'addConversationMessage':
      return {
        ...state,
        [getConversationKeyForArtifact(action.artifact)]: [
          ...state[getConversationKeyForArtifact(action.artifact)],
          action.message,
        ],
      };
    case 'setConversationMessage':
      return {
        ...state,
        [getConversationKeyForArtifact(action.artifact)]: state[
          getConversationKeyForArtifact(action.artifact)
        ].map((message, index) =>
          index === action.messageIndex ? action.message : message
        ),
      };
    case 'deleteConversationMessage':
      return {
        ...state,
        [getConversationKeyForArtifact(action.artifact)]: state[
          getConversationKeyForArtifact(action.artifact)
        ].filter((_, index) => index !== action.messageIndex),
      };
    case 'deleteConversationMessagesFrom':
      return {
        ...state,
        [getConversationKeyForArtifact(action.artifact)]: state[
          getConversationKeyForArtifact(action.artifact)
        ].filter((_, index) => index < action.messageIndex),
      };
    case 'clearConversation':
      return {
        ...state,
        [getConversationKeyForArtifact(action.artifact)]: [],
      };
    case 'updateSpec':
      let existingEntryIndex = state.spec.findIndex((entry) =>
        areSameSelectedTypes(
          entry.selectedThingTypes,
          action.selectedThingTypes
        )
      );

      if (existingEntryIndex !== -1) {
        return {
          ...state,
          spec: state.spec.map((specEntry, i) => {
            if (i !== existingEntryIndex) {
              return specEntry;
            }

            return {
              ...specEntry,
              text: action.text,
            };
          }),
        };
      }

      return {
        ...state,
        spec: [
          ...state.spec,
          {
            selectedThingTypes: action.selectedThingTypes,
            text: action.text,
          },
        ],
      };
    case 'clearSpec':
      return {
        ...state,
        spec: [],
      };
    case 'updateSpecAtTimeOfLastCodeGeneration':
      return {
        ...state,
        specAtTimeOfLastCodeGeneration: action.value,
      };
    case 'clearSpecAtTimeOfLastCodeGeneration':
      return {
        ...state,
        specAtTimeOfLastCodeGeneration: [],
      };
    case 'clearCode':
      return {
        ...state,
        code: '',
      };
    case 'updateCode':
      return {
        ...state,
        code: action.code,
      };
    case 'updateCodeAtTimeOfLastRun':
      return {
        ...state,
        codeAtTimeOfLastRun: action.value,
      };
    case 'updateIndexOfCodeMessageBeingViewed':
      return {
        ...state,
        indexOfCodeMessageBeingViewed: action.indexOfCodeMessageBeingViewed,
      };
    case 'updateCodeEditingSelectedThingTypeAndEventTypeIfExists':
      return {
        ...state,
        codeEditingSelectedThingTypeAndEventTypeIfExists:
          action.thingTypeAndEventTypeIfExists,
      };
    case 'updateSelectedColor':
      return {
        ...state,
        selectedColor: action.selectedColor,
        selectedTool: DrawingTool.PEN,
      };
    case 'updateSelectedTool':
      return {
        ...state,
        selectedTool: action.selectedTool,
      };

    default:
      throw new Error('Invalid action');
  }
}

export type EditorOnlyStateAction =
  | {
      type: 'updateSelectedThingsIndices';
      selectedThingsIndices: Array<number>;
    }
  | {
      type: 'clearSelectedThingsIndices';
    }
  | { type: 'updateSelectedTab'; selectedTab: Tab }
  | { type: 'updateConversationInput'; conversationInput: string }
  | { type: 'addConversationMessage'; artifact: Artifact; message: Message }
  | {
      type: 'setConversationMessage';
      artifact: Artifact;
      messageIndex: number;
      message: Message;
    }
  | {
      type: 'deleteConversationMessage';
      artifact: Artifact;
      messageIndex: number;
    }
  | {
      type: 'deleteConversationMessagesFrom';
      artifact: Artifact;
      messageIndex: number;
    }
  | { type: 'clearConversation'; artifact: Artifact }
  | { type: 'updateSpec'; selectedThingTypes: Array<string>; text: string }
  | { type: 'clearSpec' }
  | {
      type: 'updateSpecAtTimeOfLastCodeGeneration';
      value: Array<SpecEntry>;
    }
  | { type: 'clearSpecAtTimeOfLastCodeGeneration' }
  | { type: 'clearCode' }
  | {
      type: 'updateCode';
      code: string;
    }
  | { type: 'updateCodeAtTimeOfLastRun'; value: string }
  | {
      type: 'updateIndexOfCodeMessageBeingViewed';
      indexOfCodeMessageBeingViewed: number | null;
    }
  | {
      type: 'updateCodeEditingSelectedThingTypeAndEventTypeIfExists';
      thingTypeAndEventTypeIfExists: CodeEditingSelectedThingTypeAndEventType | null;
    }
  | { type: 'updateSelectedColor'; selectedColor: string }
  | { type: 'updateSelectedTool'; selectedTool: DrawingTool };

export function getConversationKeyForArtifact(artifact: Artifact) {
  switch (artifact) {
    case Artifact.SPEC:
      return 'specConversation';
    case Artifact.CODE:
      return 'codeConversation';
    default:
      exhaustiveGuard(artifact);
  }
}

export function mergeCodeOrThrowIfCodeDoesNotCompile(
  existingCode: string,
  codeFragment: string,
  codeMerge: CodeMerge
) {
  let codeFragmentandlersByThingType: any;
  try {
    codeFragmentandlersByThingType = JSON.parse(codeFragment);
  } catch (e: any) {
    throw new Error(e);
  }

  assertTruthy(codeFragmentandlersByThingType);

  // Check we can compile all the handlers
  Object.keys(
    codeFragmentandlersByThingType as unknown as HandlersByType
  ).forEach((thingType: string) => {
    Object.keys(
      codeFragmentandlersByThingType[thingType] as unknown as HandlerByEventName
    ).forEach((handlerName: string) => {
      const f = compileHandlerCodeToFunction(
        codeFragmentandlersByThingType[thingType][handlerName],
        handlerName as ThingEvent,
        thingType
      );

      if (!f) {
        throw new Error(
          'Not saving code because could not compile code for a handler. See error above.'
        );
      }
    });
  });

  const mergedCode = mergeCode(existingCode, codeFragment, codeMerge);

  return mergedCode;
}

export function mergeHandlerOrThrowIfCodeDoesNotCompile(
  existingCode: string,
  handlerCode: string,
  codeMerge: CodeMergeTextEdit
) {
  const codeFragment = JSON.stringify({
    [codeMerge.thingType]: {
      [codeMerge.eventType]: handlerCode,
    },
  });

  return mergeCodeOrThrowIfCodeDoesNotCompile(
    existingCode,
    codeFragment,
    codeMerge
  );
}

function areSameSelectedTypes(
  selectedTypes1: Array<string>,
  selectedTypes2: Array<string>
) {
  return isEqual(
    selectedTypes1.concat().sort(),
    selectedTypes2.concat().sort()
  );
}
