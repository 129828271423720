export function getUniqueThingType(previousIndex: number) {
  const nextIndex = previousIndex + 1;

  return {
    thingType: encodeNumberToLetters(nextIndex),
    nextIndex: previousIndex + 1,
  };
}

export function getFirstNumberThatWillEncodeAsAFourLetterThingType() {
  return 18278;
}

function encodeNumberToLetters(num: number): string {
  let str = '';
  while (num > 0) {
    let mod = (num - 1) % 26;
    str = String.fromCharCode(97 + mod) + str;
    num = Math.floor((num - mod) / 26);
  }
  return str;
}
