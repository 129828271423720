import { ThingEvent, assertTruthy } from './types';
import { AppDispatch, logLineAdd } from './useData';

export class ConsoleWrapper {
  dispatch: AppDispatch;
  thingType: string;
  eventType: ThingEvent;
  originalLogFunction: ((...data: any[]) => void) | null;
  originalErrorFunction: ((...data: any[]) => void) | null;

  constructor(dispatch: AppDispatch, thingType: string, eventType: ThingEvent) {
    this.dispatch = dispatch;
    this.thingType = thingType;
    this.eventType = eventType;
    this.originalLogFunction = null;
    this.originalErrorFunction = null;
  }

  wrap() {
    const dispatch = this.dispatch;
    const thingType = this.thingType;
    const eventType = this.eventType;

    this.originalLogFunction = window.console.log;
    const originalLogFunction = window.console.log;

    window.console.log = function () {
      dispatch(
        logLineAdd({
          type: 'log',
          args: Array.from(arguments),
          date: Date.now(),
          thingType,
          eventType,
        })
      );
      assertTruthy(originalLogFunction);
      originalLogFunction(...Array.from(arguments));
    };

    this.originalErrorFunction = window.console.error;
    const originalErrorFunction = window.console.error;

    window.console.error = function () {
      dispatch(
        logLineAdd({
          type: 'error',
          args: Array.from(arguments),
          date: Date.now(),
          thingType,
          eventType,
        })
      );
      assertTruthy(originalErrorFunction);
      originalErrorFunction(...Array.from(arguments));
    };
  }

  unwrap() {
    assertTruthy(this.originalLogFunction);
    window.console.log = this.originalLogFunction;

    assertTruthy(this.originalErrorFunction);
    window.console.error = this.originalErrorFunction;
  }
}
