import Editor from '@monaco-editor/react';
import classNames from 'classnames';
import * as monaco from 'monaco-editor';
import { useEffect, useRef, useState } from 'react';

export function CodeEditor({
  value,
  language,
  onChange,
  hasError,
}: {
  value: string;
  language: string;
  onChange: (value: string) => void;
  hasError?: boolean;
}) {
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const [haveSetEol, setHaveSetEol] = useState(false);

  // This is fucking annoying.  When formatting code with
  // `storedCodeJsonStringToJsonStringWithReadableCode` to show it in Monaco, we
  // replace \\n inside function strings "function on..." with \r\n so the
  // function code has line breaks when rendered (and isn't just all on one
  // line).  However, Monaco guesses what line endings are being used and if
  // there more \n than \r\n (which there will be if JSON code blobs like {"A":
  // {"onTick": "...short fn"}} because A", onTick" and the braces all have \n
  // after them and the only \r\n will be the one line break in the short fn).
  // So it strips out the \r\n we put in for formatting and replaces them with
  // \n so when we reformat the code on change before storage with
  // removeReadabilityFormattingFromValidOrInvalidJsonString we don't replace
  // the \r\n with \\n (because they're not there) and we just leave \n inside
  // the function strings which will later cause JSON.parse to choke and fail to
  // store the JSON string.  So we just force Monaco to use \r\n line endings.
  useEffect(() => {
    if (!editorRef || !editorRef.current || haveSetEol) {
      return;
    }

    editorRef.current.getModel()?.setEOL(monaco.editor.EndOfLineSequence.CRLF);
    setHaveSetEol(true);

    // Deps linter says we don't need editorRef.current, but we do, otherwise
    // this isn't re-run when the editor is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveSetEol, editorRef.current]);

  return (
    <Editor
      onMount={(editor) => {
        editorRef.current = editor;
      }}
      className={classNames('border-box rounded white p1', {
        'red-light1': hasError,
      })}
      options={{
        tabSize: 2,
        lineNumbers: 'off',
        autoIndent: 'advanced',
        renderLineHighlight: 'none',
        guides: {
          indentation: false,
          bracketPairs: false,
          bracketPairsHorizontal: false,
          highlightActiveBracketPair: false,
          highlightActiveIndentation: false,
        },
        minimap: { enabled: false },
        hover: { enabled: false },
        scrollBeyondLastLine: false,
        matchBrackets: 'near',
        hideCursorInOverviewRuler: true,
        overviewRulerBorder: false,
        overviewRulerLanes: 0,
        folding: false,
        glyphMargin: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        language,
      }}
      onChange={(newValue) => {
        if (newValue === undefined) {
          return;
        }

        onChange(newValue);
      }}
      defaultLanguage={language}
      loading=""
      value={value}
    />
  );
}
