import { useEffect } from 'react';
import { KeyModifiers } from './types';

export function useKeyDown(
  targetKey: string,
  modifiers: KeyModifiers,
  cb: (key: string, modifiers: KeyModifiers) => void
) {
  useEffect(() => {
    function downHandler({
      key,
      altKey,
      ctrlKey,
      metaKey,
      shiftKey,
    }: KeyboardEvent) {
      if (
        key === targetKey &&
        (modifiers.altKey === undefined || !!altKey) &&
        (modifiers.ctrlOrCommand === undefined || !!ctrlKey || !!metaKey) &&
        (modifiers.shiftKey === undefined || !!shiftKey)
      ) {
        cb(key, modifiers);
      }
    }

    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [targetKey, cb, modifiers]);
}
