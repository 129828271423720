import { useCallback } from 'react';
import { Api, Artifact, View } from './types';
import { SpecPanel } from './SpecPanel';
import { useEditorOnlyStateContext } from './substantiate';
import { thingDelete, useAppDispatch, useAppSelector } from './useData';

export function SpecTab({
  onRunCode,
  selectedThingTypes,
  api,
  codeView,
  setCodeView,
  onOpenSettings,
}: {
  onRunCode: (code: string) => void;
  selectedThingTypes: Array<string>;
  api: Api;
  codeView: string;
  setCodeView: (codeView: View) => void;
  onOpenSettings: () => void;
}) {
  if (!localStorage.accessKeyId) {
    return (
      <>
        Set your OpenAI API key in{' '}
        <span className="pointer text-blue" onClick={onOpenSettings}>
          ⚙️ Settings
        </span>
      </>
    );
  }

  return (
    <SpecTabWithAiKey
      onRunCode={onRunCode}
      selectedThingTypes={selectedThingTypes}
      api={api}
      codeView={codeView}
      setCodeView={setCodeView}
    />
  );
}

export function SpecTabWithAiKey({
  onRunCode,
  selectedThingTypes,
  api,
  codeView,
  setCodeView,
}: {
  onRunCode: (code: string) => void;
  selectedThingTypes: Array<string>;
  api: Api;
  codeView: string;
  setCodeView: (codeView: View) => void;
}) {
  const things = useAppSelector((state) => state.things);
  const dispatch = useAppDispatch();
  const { dispatchEditorAppState } = useEditorOnlyStateContext();

  const onDeleteGame = useCallback(() => {
    // TODO: Change this to creating a new game (and the button to New Game) -
    // we'll build new game anyway, and it will also more reliably set the
    // starting state (e.g. I forgot to add clearSelectedThingIndices below

    dispatchEditorAppState({ type: 'clearSpec' });
    dispatchEditorAppState({ type: 'clearSpecAtTimeOfLastCodeGeneration' });
    dispatchEditorAppState({ type: 'clearCode' });
    dispatchEditorAppState({
      type: 'clearConversation',
      artifact: Artifact.CODE,
    });
    dispatchEditorAppState({ type: 'clearSelectedThingsIndices' });
    things.forEach((thing) =>
      dispatch(thingDelete({ idOfThingBeingDeleted: thing.id }))
    );
    window.location.reload();
  }, [dispatchEditorAppState, things, dispatch]);

  return (
    <div className="flex height-full">
      <div style={{ minWidth: 400, maxWidth: 500 }}>
        <SpecPanel
          onRunCode={onRunCode}
          onDeleteGame={onDeleteGame}
          selectedThingTypes={selectedThingTypes}
          api={api}
          codeView={codeView}
          setCodeView={setCodeView}
        />
      </div>
    </div>
  );
}
