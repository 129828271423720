import { useCallback, useEffect, useState } from 'react';

type UseLoadedImageState =
  | { type: 'no-image' }
  | { type: 'need-to-start-loading'; dataUrl: string }
  | { type: 'loading'; image: HTMLImageElement; dataUrl: string }
  | { type: 'loaded'; image: HTMLImageElement; dataUrl: string };

export function useLoadedImage({
  dataUrlIfExists,
  shouldReloadOnUrlChange,
}: {
  dataUrlIfExists: string;
  shouldReloadOnUrlChange: boolean;
}): UseLoadedImageState {
  const getInitialLoadState = useCallback((): UseLoadedImageState => {
    return !dataUrlIfExists
      ? { type: 'no-image' }
      : { type: 'need-to-start-loading', dataUrl: dataUrlIfExists };
  }, [dataUrlIfExists]);

  const [state, setState] = useState<UseLoadedImageState>(
    getInitialLoadState()
  );

  const loadImage = useCallback(() => {
    if (!dataUrlIfExists) {
      return;
    }

    const newImage = new Image();
    newImage.onload = () => {
      setState({ type: 'loaded', image: newImage, dataUrl: dataUrlIfExists });
    };
    newImage.src = dataUrlIfExists;
    setState({ type: 'loading', image: newImage, dataUrl: dataUrlIfExists });
  }, [dataUrlIfExists]);

  useEffect(() => {
    if (
      (state.type === 'no-image' && !!dataUrlIfExists) || // user added image after initial editor load
      state.type === 'need-to-start-loading' ||
      ((state.type === 'loading' || state.type === 'loaded') &&
        state.dataUrl !== dataUrlIfExists &&
        shouldReloadOnUrlChange)
    ) {
      loadImage();
      return;
    }
  }, [
    dataUrlIfExists,
    getInitialLoadState,
    loadImage,
    shouldReloadOnUrlChange,
    state,
  ]);

  return state;
}
