import { Dialog, Text } from 'evergreen-ui';
import { useCallback, useState } from 'react';

export function SettingsButtonAndModal({
  isSettingsModalOpen,
  setIsSettingsModalOpen,
}: {
  isSettingsModalOpen: boolean;
  setIsSettingsModalOpen: (isSettingsModalOpen: boolean) => void;
}) {
  const [accessKeyId, setAccessKeyId] = useState<string>(
    localStorage.accessKeyId
  );

  const updateAccessKeyId = useCallback((accessKeyId: string) => {
    setAccessKeyId(accessKeyId);
    localStorage.accessKeyId = accessKeyId;
  }, []);

  return (
    <>
      <div
        className="pointer"
        style={{ fontSize: 26 }}
        onClick={() => setIsSettingsModalOpen(true)}
      >
        ⚙️
      </div>

      {isSettingsModalOpen && (
        <Dialog
          isShown={isSettingsModalOpen}
          title="OpenAI API access key"
          onCloseComplete={() => setIsSettingsModalOpen(false)}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEscapePress={true}
          hasCancel={false}
          confirmLabel="Done"
          width={400}
        >
          <InputAiAccessKey
            accessKeyId={accessKeyId}
            onChange={(accessKeyId) => updateAccessKeyId(accessKeyId)}
          />
        </Dialog>
      )}
    </>
  );
}

function InputAiAccessKey({
  accessKeyId,
  onChange,
}: {
  accessKeyId: string;
  onChange: (accessKeyId: string) => void;
}) {
  return (
    <div className="flex flex-column">
      <Text size="small">
        Transmitted to the Lude server when making AI requests. <br />
        Only stored on this device.
      </Text>

      <div className="flex mt1">
        <input
          name="accessKeyId"
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={accessKeyId}
          placeholder="sk-..."
          className="mr1"
          size={30}
        />
      </div>
    </div>
  );
}
