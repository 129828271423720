import { Api, Coordinates, Thing, ThingUpdate } from './types';
import _ from 'lodash';
import { useMemo } from 'react';

export function useApi(
  onCreateThing: (update: { [key: string]: any }) => Thing,
  onDeleteThing: (thing: Thing) => void,
  onDuplicateThing: (thing: Thing) => Thing,
  onFireEvent: (
    thing: Thing,
    api: Api,
    things: Array<Thing>,
    event: string,
    data: { [key: string]: any }
  ) => void,
  playScreenSize: { width: number; height: number }
) {
  return useMemo(
    () => ({
      _,

      vectorBetween: (startPoint: Coordinates, endPoint: Coordinates) => {
        return {
          x: endPoint.x - startPoint.x,
          y: endPoint.y - startPoint.y,
        };
      },

      unitVector: function (vector: Coordinates) {
        const magnitude = this.magnitude(vector);
        if (magnitude === 0) {
          return { x: 0, y: 0 };
        }

        return {
          x: vector.x / magnitude,
          y: vector.y / magnitude,
        };
      },

      magnitude: function (vector: Coordinates) {
        return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
      },

      rect: function (thing: Thing) {
        return {
          x: thing.x,
          y: thing.y,
          width: thing.width,
          height: thing.height,
          top: thing.y,
          bottom: thing.y + thing.height,
          left: thing.x,
          right: thing.x + thing.width,
          angle: thing.angle,
        };
      },

      createThing: function (update: ThingUpdate): Thing {
        return onCreateThing(update);
      },

      deleteThing: function (thing: Thing) {
        onDeleteThing(thing);
      },

      duplicateThing: function (thing: Thing) {
        return onDuplicateThing(thing);
      },

      fireEvent: function (
        thing: Thing,
        things: Array<Thing>,
        event: string,
        data: { [key: string]: any }
      ) {
        onFireEvent(
          thing,
          // Kind of gross - we're passing the API by passing our current
          // context. It's tricky because we can't pass it when calling
          // runUpdateThingHandler to fire the event because API and onFireEvent
          // are circularly dependent on each other.
          this,
          things,
          event,
          data
        );
      },

      getParent(thing: Thing, things: Array<Thing>) {
        return things.find((t) => t.id === thing.parentId) || null;
      },

      getScreenSize() {
        return { ...playScreenSize };
      },
    }),
    [
      onCreateThing,
      onDeleteThing,
      onDuplicateThing,
      onFireEvent,
      playScreenSize,
    ]
  );
}
