import { useCallback, useEffect, MouseEvent } from 'react';

export function useMouseUpListener(onMouseUpCallback: (e: MouseEvent) => void) {
  const onMouseUp = useCallback(
    (e) => {
      onMouseUpCallback(e);
    },
    [onMouseUpCallback]
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseUp]);
}
