import { createClient } from '@liveblocks/client';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import { liveblocksEnhancer } from '@liveblocks/redux';
import { TypedUseSelectorHook } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  Artifact,
  Conversation,
  EditorOnlyState,
  HandlersByType,
  PlayScreenSize,
  RoomEvent,
  SpecEntry,
  Thing,
  ThingEvent,
  ThingUpdate,
  ThingUpdateFn,
  assertTruthy,
  exhaustiveGuard,
  Fps,
  Runtime,
  CodeEditingSelectedThingTypeAndIfExistsEventType,
  LogLine,
} from './types';
import { cloneDeep, compact, isEqual } from 'lodash';
import { v4 } from 'uuid';
import { createRoomContext } from '@liveblocks/react';
import { sha256 } from 'js-sha256';
import { getFirstNumberThatWillEncodeAsAFourLetterThingType } from './getUniqueThingType';
import { getHandlersByTypeFromCode } from './runCode';

const LOCALHOST_ORIGIN = 'http://192.168.6.136:3000';

export const client = createClient({
  publicApiKey: 'pk_dev_X7bToyrd3Q-MKrv0xagO_bQe',
});

type LiveblocksState = {
  isStorageLoading: boolean;
};

export type SharedState = {
  liveblocks: LiveblocksState | null;

  isPaused: boolean;
  lastTimeIOsDetected: number;
  fps: number;
  previousThingTypeIndex: number;
  logLines: Array<LogLine>;
} & GameState &
  PublishState;

export type GameState = {
  things: Array<Thing>;
  playScreenSize: PlayScreenSize;
  handlersByType: HandlersByType;
  images: { [id: string]: string };
};

export type PublishState = {
  publishedVersionHashIfExists: string | null;
  slug: string;
};

const initialThingArray: Array<Thing> = [
  {
    color: 'black',
    textColor: 'white',
    isHidden: true,
    canMove: false,
    canCollide: false,
    width: 60,
    height: 40,
    x: 150,
    y: 700,
    angle: 0,
    density: 0.001,
    xVelocity: 0,
    yVelocity: 0,
    type: 'game',
    id: 0,
    parentId: null,
    imageId: 0,
  },
];

const initialState: SharedState = {
  liveblocks: null,

  // Game publish state
  publishedVersionHashIfExists: null,
  slug: Math.random().toString(36).substring(4),

  // Game state that gets published
  things: initialThingArray,
  playScreenSize: { width: 393, height: 660 },
  handlersByType: {},
  images: {},

  // Editor shared state
  isPaused: true,
  lastTimeIOsDetected: 0,
  fps: Fps.sixty,
  previousThingTypeIndex: getFirstNumberThatWillEncodeAsAFourLetterThingType(),
  logLines: [],
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    updateAllData: (state, action: { payload: SharedState; type: string }) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateIsPaused: (
      state,
      action: { payload: { isPaused: boolean }; type: string }
    ) => {
      return {
        ...state,
        isPaused: action.payload.isPaused,
      };
    },
    updatePlayScreenSize: (
      state,
      action: { payload: { playScreenSize: PlayScreenSize }; type: string }
    ) => {
      return {
        ...state,
        playScreenSize: action.payload.playScreenSize,
      };
    },
    updateLastTimeIOsDetected: (
      state,
      action: { payload: { lastTimeIOsDetected: number }; type: string }
    ) => {
      return {
        ...state,
        lastTimeIOsDetected: action.payload.lastTimeIOsDetected,
      };
    },
    updateFps: (state, action: { payload: { fps: number }; type: string }) => {
      return {
        ...state,
        fps: action.payload.fps,
      };
    },
    updatePreviousThingTypeIndex: (
      state,
      action: {
        payload: { previousThingTypeIndex: number };
        type: string;
      }
    ) => {
      return {
        ...state,
        previousThingTypeIndex: action.payload.previousThingTypeIndex,
      };
    },
    publish: (state) => {
      const gameState = {
        things: state.things,
        playScreenSize: state.playScreenSize,
        handlersByType: state.handlersByType,
        images: state.images,
      };

      return {
        ...state,
        publishedVersionHashIfExists: getPublishedVersionHash(gameState),
      };
    },
    unpublish: (state) => {
      return {
        ...state,
        publishedVersionHashIfExists: null,
      };
    },
    thingCreate: (
      state,
      action: { payload: { thing: Thing }; type: string }
    ) => {
      return {
        ...state,
        things: state.things.concat(action.payload.thing),
      };
    },
    thingUpdate: (
      state,
      action: { payload: { index: number; update: ThingUpdate }; type: string }
    ) => {
      const thing = state.things[action.payload.index];

      if (!thing) {
        return state;
      }

      return {
        ...state,
        things: state.things.map((thing, i) => {
          if (i !== action.payload.index) {
            return thing;
          }

          return {
            ...thing,
            ...action.payload.update,
          };
        }),
      };
    },
    thingPropertyDelete: (
      state,
      action: { payload: { index: number; key: keyof Thing }; type: string }
    ) => {
      const thing = state.things[action.payload.index];

      if (!thing) {
        return state;
      }

      return {
        ...state,
        things: state.things.map((thing, i) => {
          if (i !== action.payload.index) {
            return thing;
          }

          const newThing = { ...thing };
          delete newThing[action.payload.key];

          return newThing;
        }),
      };
    },
    // TODO: Make non-stateful
    thingDelete: (
      state,
      action: { payload: { idOfThingBeingDeleted: number }; type: string }
    ) => {
      const things = state.things;

      function isThingBeingDeletedOrDescendent(thingId: number) {
        let currentId: number | null = thingId;
        while (true) {
          if (currentId === null) {
            return false;
          }

          if (currentId === action.payload.idOfThingBeingDeleted) {
            return true;
          }

          // A linter disallows functions inside loops (presumably because it
          // might create an async closure), but that's not the case here
          // eslint-disable-next-line
          const thing = things.find((t) => t.id === currentId);

          if (!thing) {
            return false;
          }

          assertTruthy(thing);

          currentId = thing.parentId;
        }
      }

      let indicesOfThingsToDelete: Array<number> = [];
      things.forEach((thing: Thing, index: number) => {
        if (!isThingBeingDeletedOrDescendent(thing.id)) {
          return;
        }

        indicesOfThingsToDelete.push(index);
      });

      indicesOfThingsToDelete
        .sort((a, b) => b - a) // delete from end to start so indices don't change
        .forEach((index) => {
          state.things.splice(index, 1);
        });
    },
    // TODO: Make non-stateful
    handlersByTypeUpdate: (
      state,
      action: {
        payload: {
          type: string;
          eventName: ThingEvent;
          update: { code?: string; prompt?: string };
        };
        type: string;
      }
    ) => {
      const handlersByType = state.handlersByType;

      const typeHandlers = handlersByType[action.payload.type];
      if (typeHandlers === undefined) {
        handlersByType[action.payload.type] = {
          [action.payload.eventName]: action.payload.update,
        };
        return;
      }

      typeHandlers[action.payload.eventName] = {
        ...typeHandlers[action.payload.eventName],
        ...action.payload.update,
      };
    },
    // TODO: Make non-stateful
    handlersByTypeClear: (state) => {
      state.handlersByType = {};
    },
    logLineAdd: (state, action: { payload: LogLine; type: string }) => {
      const logLinesToRetainCount = 50;

      return {
        ...state,
        logLines: state.logLines
          .concat({ ...action.payload })
          .slice(
            state.logLines.length - logLinesToRetainCount >= 0
              ? state.logLines.length - logLinesToRetainCount
              : 0
          ),
      };
    },
    logClear: (state) => {
      return {
        ...state,
        logLines: [],
      };
    },
    imageUpdate: (
      state,
      action: { payload: { imageIndex: number; image: string }; type: string }
    ) => {
      const images = { ...state.images };
      images[action.payload.imageIndex] = action.payload.image;

      return {
        ...state,
        images,
      };
    },
    imageAdd: (state, action: { payload: { image: string }; type: string }) => {
      const images = {
        ...state.images,
        [Object.keys(state.images).length]: action.payload.image,
      };

      return {
        ...state,
        images,
      };
    },
  },
});

const runtime = getRuntime(window.location);

// Create a store, passing our reducer function and our initial state
export const sharedStateStore = configureStore({
  reducer: slice.reducer,
  ...(runtime === Runtime.EDITOR || runtime === Runtime.SIMULATOR
    ? {
        enhancers: [
          liveblocksEnhancer<SharedState>({
            client,
            storageMapping: {
              publishedVersionHashIfExists: true,
              slug: true,

              things: true,
              playScreenSize: true,
              handlersByType: true,
              images: true,

              isPaused: true,
              lastTimeIOsDetected: true,
              fps: true,
              previousThingTypeIndex: true,
              logLines: true,
            },
          }),
        ],
      }
    : {}),
});

// @ts-ignore
window.sharedStateStore = sharedStateStore;

export const {
  updateIsPaused,
  updatePlayScreenSize,
  updateLastTimeIOsDetected,
  updateFps,
  thingCreate,
  thingUpdate,
  thingPropertyDelete,
  thingDelete,
  handlersByTypeUpdate,
  handlersByTypeClear,
  publish,
  unpublish,
  updateAllData,
  updatePreviousThingTypeIndex,
  logLineAdd,
  logClear,
  imageUpdate,
  imageAdd,
} = slice.actions;

export type AppDispatch = typeof sharedStateStore.dispatch;
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<SharedState> = useSelector;

export function getConversation(
  editorOnlyState: EditorOnlyState,
  artifact: Artifact
) {
  const key = getConversationKeyForArtifact(artifact);

  const conversation = editorOnlyState[key] as Conversation;

  return conversation;
}

export function getConversationKeyForArtifact(artifact: Artifact) {
  switch (artifact) {
    case Artifact.SPEC:
      return 'specConversation';
    case Artifact.CODE:
      return 'codeConversation';
    default:
      exhaustiveGuard(artifact);
  }
}

export function reportGameEngineThingChangesToServer(
  thing: Thing,
  newThing: Thing,
  thingIndex: number,
  thingUpdate: ThingUpdateFn
) {
  const newThingOnlyChanges = Object.entries(newThing).reduce(
    (acc, [key, value]: [key: string, value: any]) => {
      // @ts-ignore-next-line
      if (!isEqual(thing[key], value)) {
        // @ts-ignore-next-line
        acc[key] = value;
      }

      return acc;
    },
    {} as Thing
  );

  if (Object.keys(newThingOnlyChanges).length === 0) {
    return;
  }

  thingUpdate(thingIndex, newThingOnlyChanges);
}

export function getNewThings(things: Array<Thing>) {
  return cloneDeep(things);
}

export function getTextForSelectedThingTypes(
  spec: Array<SpecEntry>,
  selectedThingTypes: Array<string>
) {
  const entry = spec.find((entry) =>
    areSameSelectedTypes(entry.selectedThingTypes, selectedThingTypes)
  );

  return entry?.text ?? '';
}

export function getCodeForSelectedThingTypeAndEventType(
  code: string,
  codeEditingSelectedThingTypeAndEventType: CodeEditingSelectedThingTypeAndIfExistsEventType
) {
  if (codeEditingSelectedThingTypeAndEventType.eventType === null) {
    return null;
  }

  const handlersByType = getHandlersByTypeFromCode(code);

  const codeIfExists =
    handlersByType[codeEditingSelectedThingTypeAndEventType.thingType]?.[
      codeEditingSelectedThingTypeAndEventType.eventType
    ]?.code ?? null;

  return codeIfExists;
}

function areSameSelectedTypes(
  selectedTypes1: Array<string>,
  selectedTypes2: Array<string>
) {
  return isEqual(
    selectedTypes1.concat().sort(),
    selectedTypes2.concat().sort()
  );
}

export function getRuntime(location: Location): Runtime {
  if (location.pathname.match('^/play/')) {
    // TODO: Support playing on desktop

    return Runtime.PLAYER;
  }

  if (location.pathname.match('^/simulate/')) {
    // TODO: Support simulating on desktop

    return Runtime.SIMULATOR;
  }

  return Runtime.EDITOR;
}

export function getEditorStorageId(window: Window) {
  const storageIdInUrl = getEditorStorageIdInUrlIfExists(window);
  const storageIdInLocalStorage = getStorageIdInLocalStorage(localStorage);

  if (
    !!storageIdInUrl &&
    !!storageIdInLocalStorage &&
    storageIdInUrl === storageIdInLocalStorage
  ) {
    return storageIdInUrl;
  }

  // Handles case where there's a storageId in the URL.  Either there's no
  // storageId in localStorage (because I deleted it by hand) and we want to set
  // it, or there's a different storageId in localStorage and we are trying to
  // override it with the one in the URL.
  if (!!storageIdInUrl) {
    setStorageIdInLocalStorage(localStorage, storageIdInUrl);
    return storageIdInUrl;
  }

  // Handles case where we've returned to the site but omitted storageId
  // from the URL. Just pull the storageId from localStorage, set it in the URL
  // and move on.
  if (!!storageIdInLocalStorage) {
    setStorageIdInUrlForEditor(window, storageIdInLocalStorage);
    return storageIdInLocalStorage;
  }

  assertTruthy(
    false,
    'We should not be able to call getStorageId with no storageId set'
  );
}

export function getSimulatorUrl(window: Window, storageId: string) {
  const origin = window.location.origin.match('localhost')
    ? LOCALHOST_ORIGIN
    : window.location.origin;

  return `${origin}/simulate/${storageId}`;
}

export function getEditorUrl(window: Window, storageId: string) {
  const origin = window.location.origin.match('localhost')
    ? LOCALHOST_ORIGIN
    : window.location.origin;

  return `${origin}/edit/${storageId}`;
}

export function getSimulatorStorageIdInUrl(location: Location) {
  const match = location.pathname.match('^/simulate/(.+)');
  assertTruthy(match);
  const storageId: string = match[1];

  return storageId;
}

export function getPlayerSlugInUrl(location: Location) {
  const match = location.pathname.match('^/play/(.+)');
  assertTruthy(match);
  const slug: string = match[1];

  return slug;
}

function setStorageIdInLocalStorage(localStorage: Storage, storageId: string) {
  localStorage.setItem('storageId', storageId);
}

function getStorageIdInLocalStorage(localStorage: Storage) {
  const localStorageStorageId = localStorage.getItem('storageId');

  return localStorageStorageId;
}

function setStorageIdInUrlForEditor(window: Window, storageId: string) {
  window.location.href = getEditorUrl(window, storageId);
}

function getEditorStorageIdInUrlIfExists(window: Window) {
  const editMatch = window.location.pathname.match('^/edit/(.+)');

  if (editMatch) {
    const storageId = editMatch[1];

    return storageId;
  }

  return null;
}

function createStorageId() {
  return v4();
}

export function getPublishedVersionHash(gameState: GameState) {
  const gameStateHash = sha256(JSON.stringify(gameState));

  return gameStateHash;
}

export function getShareUrl(slug: string) {
  return `${window.location.origin}/play/${slug}`;
}

// This isn't ideal. We do this because things in Redux may still be empty
// because it's loading (and so we'll end up with undefined in
// selectedThings). But there's no way to tell that.
export function getSelectedThings(
  things: Array<Thing>,
  indices: Array<number>
) {
  return compact(indices.map((index) => things[index]));
}

export function ifNoStorageUrlCreateAndEitherWayPutInUrlAndLocalStorage(
  window: Window,
  localStorage: Storage
) {
  const storageIdInUrl = getEditorStorageIdInUrlIfExists(window);

  if (storageIdInUrl) {
    setStorageIdInLocalStorage(localStorage, storageIdInUrl);
    return storageIdInUrl;
  }

  const storageIdInLocalStorage = getStorageIdInLocalStorage(localStorage);
  if (storageIdInLocalStorage) {
    setStorageIdInUrlForEditor(window, storageIdInLocalStorage);
    return storageIdInLocalStorage;
  }

  const newStorageId = createStorageId();
  setStorageIdInLocalStorage(localStorage, newStorageId);
  setStorageIdInUrlForEditor(window, newStorageId);

  return newStorageId;
}

export function broadcastEventToCurrentRoom(event: RoomEvent) {
  return client
    .getRoom<any, any, any, RoomEvent>(getEditorStorageId(window))!
    .broadcastEvent(event);
}

export const {
  suspense: { RoomProvider, useEventListener },
} = createRoomContext<any, any, any, RoomEvent>(client);
