import { ErrorBoundary } from './ErrorBoundary';
import { RenderedOrGenericThing } from './RunningGame';
import { INVALID_MESSAGE } from './thingHandlers';
import { Api, Thing } from './types';
import { useAppSelector } from './useData';

export function ThingRenderedAsIcon({
  thing,
  api,
  sizeInPixels,
}: {
  thing: Thing;
  api: Api;
  sizeInPixels: number;
}) {
  const things = useAppSelector((state) => state.things);

  return (
    <div
      className="overflow-hidden"
      style={{
        width: sizeInPixels,
        height: sizeInPixels,
      }}
    >
      <ErrorBoundary FallbackComponent={(e) => <>{INVALID_MESSAGE}</>}>
        <div
          style={{
            marginLeft: (sizeInPixels - thing.width) / 2,
            marginTop: (sizeInPixels - thing.height) / 2,
          }}
        >
          <RenderedOrGenericThing thing={thing} api={api} things={things}>
            {null}
          </RenderedOrGenericThing>
        </div>
      </ErrorBoundary>
    </div>
  );
}
