import classNames from 'classnames';
import { Thing } from './types';
import { useAppSelector } from './useData';
import { useLoadedImage } from './useLoadedImage';

export function RenderedThing({
  thing,
  children, // these are real rendered child things (null if no children)
}: {
  thing: Thing;
  children: React.ReactNode;
}) {
  const images = useAppSelector((state) => state.images);
  const imageResult = useLoadedImage({
    dataUrlIfExists: images[thing.imageId] ?? null,
    shouldReloadOnUrlChange: true,
  });

  if (children) {
    return <>{children}</>;
  }

  if (imageResult.type === 'no-image') {
    return (
      <div
        style={{
          backgroundColor: thing.color,
          color: thing.textColor ?? 'white',
          width: thing.width,
          height: thing.height,
        }}
        className="no-user-select flex justify-center items-center rounded border-box height-full"
      >
        {thing.text ? thing.text : thing.type}
      </div>
    );
  }

  return (
    <div className="no-user-select">
      {imageResult.type === 'loaded' ? (
        <img
          src={imageResult.image!.src}
          alt="Thing"
          draggable={false}
          style={{
            width: thing.width,
            height: thing.height,
          }}
        />
      ) : (
        <div
          className={classNames('flex justify-center items-center height-full')}
          style={{ width: thing.width, height: thing.height }}
        >
          ...
        </div>
      )}
    </div>
  );
}
