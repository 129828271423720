import { useState } from 'react';
import { Coordinates, assertTruthy } from './types';
import { useMouseMoveListener } from './useMouseMoveListener';
import { useMouseUpListener } from './useMouseUpListener';

export function useDraggableThing({
  origin,
  onStart,
  onMove,
  onStop,
}: {
  origin: Coordinates;
  onStart: (e: React.MouseEvent) => void;
  onMove: ({ x, y }: { x: number; y: number }) => void;
  onStop: (e: React.MouseEvent) => void;
}): [boolean, { onMouseDown: (e: React.DragEvent<HTMLDivElement>) => void }] {
  const [
    offsetBetweenGripPositionAndTopLeftOfObjectBeingDragged,
    setOffsetBetweenGripPositionAndTopLeftOfObjectBeingDragged,
  ] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  useMouseMoveListener((e) => {
    if (!isDragging) {
      return;
    }

    assertTruthy(offsetBetweenGripPositionAndTopLeftOfObjectBeingDragged);

    onMove({
      x:
        e.clientX -
        offsetBetweenGripPositionAndTopLeftOfObjectBeingDragged.x -
        origin.x,
      y:
        e.clientY -
        offsetBetweenGripPositionAndTopLeftOfObjectBeingDragged.y -
        origin.y,
    });
  });

  useMouseUpListener((e) => {
    if (!isDragging) {
      return;
    }

    setIsDragging(false);
    onStop(e);
  });

  return [
    isDragging,
    {
      onMouseDown: (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsDragging(true);
        onStart(e);

        const targetRect = e.currentTarget.getBoundingClientRect();

        setOffsetBetweenGripPositionAndTopLeftOfObjectBeingDragged({
          x: e.clientX - targetRect.x,
          y: e.clientY - targetRect.y,
        });
      },
    },
  ];
}
