import { Button } from 'evergreen-ui';
import { Api, Thing } from './types';
import { ThingRenderedAsIcon } from './ThingRenderedAsIcon';
import classNames from 'classnames';

const RENDER_SIZE = 32;

export function SelectedElementToolbar({
  selectedThings,
  onDeleteThing,
  onBringOnScreen,
  onDuplicateThing,
  api,
}: {
  selectedThings: Array<Thing>;
  onDeleteThing: () => void;
  onBringOnScreen: () => void;
  onDuplicateThing: () => void;
  api: Api;
}) {
  const isExactlyOneSelectedThing = selectedThings.length === 1;
  const selectedThing = selectedThings[0];

  return (
    <div className="flex items-center">
      {isExactlyOneSelectedThing ? (
        <ThingRenderedAsIcon
          thing={selectedThing}
          api={api}
          sizeInPixels={RENDER_SIZE}
        />
      ) : (
        <div
          className="border border-gray-light1 rounded border-box"
          style={{ width: RENDER_SIZE, height: RENDER_SIZE }}
        ></div>
      )}

      <Button
        disabled={!isExactlyOneSelectedThing}
        className="ml1"
        onClick={onBringOnScreen}
      >
        ✊ Bring on screen
      </Button>

      <Button
        disabled={!isExactlyOneSelectedThing}
        className="ml1"
        onClick={onDuplicateThing}
      >
        📑 Duplicate
      </Button>

      <Button
        disabled={!isExactlyOneSelectedThing}
        className="ml1"
        intent="danger"
        onClick={onDeleteThing}
      >
        <div className="flex items-center">
          <div>🗑️ Delete</div>{' '}
          <div
            className={classNames('ml-half', {
              'text-light-gray': isExactlyOneSelectedThing,
            })}
          >
            ⌘⌫
          </div>
        </div>
      </Button>
    </div>
  );
}
