import Matter, { Engine, Bodies, Composite, Body, Events } from 'matter-js';
import { useEffect, useState } from 'react';
import { broadcastEventToCurrentRoom } from './useData';
import {
  Thing,
  assertTruthy,
  Coordinates,
  Dimensions,
  Collision,
} from './types';
import { isIOs } from './isIOs';
import { difference } from 'lodash';

let hasPhysicsBeenSetup = false;

export function usePhysicsEngineIfIOs({
  initialThings,
  isIOs,
  onCollisions,
}: {
  initialThings: Array<Thing>;
  isIOs: boolean;
  onCollisions: (collisions: Array<Collision>) => void;
}): Engine | null {
  const [physicsEngine] = useState<Engine | null>(
    isIOs ? createPhysicsEngine(onCollisions) : null
  );

  useEffect(() => {
    if (!isIOs || hasPhysicsBeenSetup) {
      return;
    }

    hasPhysicsBeenSetup = true;

    assertTruthy(physicsEngine);

    initialThings.forEach((thing: Thing) => {
      addBodyForThing(thing, physicsEngine);
    });
  }, [initialThings, isIOs, physicsEngine]);

  return physicsEngine;
}

function createPhysicsEngine(
  onCollisions: (collisions: Array<Collision>) => void
) {
  const physicsEngine = Engine.create({
    enableSleeping: false,
    gravity: {
      x: 0,
      y: 0,
    },
  });

  // Without this, objects glancing off walls at very shallow angles would slide
  // along the wall rather than bouncing
  // @ts-ignore
  Matter.Resolver._restingThresh = 0.001;

  Events.on(physicsEngine, 'collisionStart', function (event) {
    onCollisions(
      event.pairs.flatMap((pair) => [
        {
          bodyId: pair.bodyA.id,
          otherBodyId: pair.bodyB.id,
        },
        {
          bodyId: pair.bodyB.id,
          otherBodyId: pair.bodyA.id,
        },
      ])
    );
  });

  return physicsEngine;
}

export function addBodyForThing(thing: Thing, physicsEngine: Engine) {
  const bodyCoordinates = thingToBodyCoordinates(
    { x: thing.x, y: thing.y },
    { width: thing.width, height: thing.height }
  );

  const body = Bodies.rectangle(
    bodyCoordinates.x,
    bodyCoordinates.y,
    thing.width,
    thing.height,
    {
      id: thing.id,
      isStatic: !thing.canMove,
      density: thing.density || 0.001,
      inertia: Infinity, // stops rotation
      inverseInertia: 0, // required when you set inertia
      restitution: 1,
      frictionAir: 0, // default is 0.01 which is why objects wouldn't maintain speed
      friction: 0,
      ...(thing.canCollide === false
        ? {
            collisionFilter: {
              group: -1,
              category: 2,
              mask: 0,
            },
          }
        : {}),
    }
  );

  Body.setVelocity(body, {
    x: thing.xVelocity !== 0 ? thing.xVelocity : 0,
    y: thing.yVelocity !== 0 ? thing.yVelocity : 0,
  });

  Composite.add(physicsEngine.world, body);
}

export function updateBodyPositionAfterThingMove(
  physicsEngine: Engine,
  thingId: number,
  thingCoordinates: Coordinates,
  thingDimensions: Dimensions
) {
  const body = getBodyById(physicsEngine, thingId);

  const newBodyPosition = thingToBodyCoordinates(
    thingCoordinates,
    thingDimensions
  );

  Matter.Body.setPosition(body, { x: newBodyPosition.x, y: newBodyPosition.y });
}

export function reportThingMoveToPhysicsEngineFromRemote(
  thing: Thing,
  newPosition: Coordinates
) {
  if (thing.x === newPosition.x && thing.y === newPosition.y) {
    return;
  }

  broadcastEventToCurrentRoom({
    type: 'thingMove',
    thingId: thing.id,
    position: newPosition,
    dimensions: { width: thing.width, height: thing.height },
  });
}

export function reportThingSizeChangeToPhysicsEngineFromRemote(
  thing: Thing,
  newSize: { width: number; height: number }
) {
  if (thing.width === newSize.width && thing.height === newSize.height) {
    return;
  }

  broadcastEventToCurrentRoom({
    type: 'thingSizeChange',
    thingId: thing.id,
    newSize,
  });
}

export function reportThingVelocityChangeToPhysicsEngineFromRemote(
  thing: Thing,
  newVelocity: { xVelocity: number; yVelocity: number }
) {
  if (
    thing.xVelocity === newVelocity.xVelocity &&
    thing.y === newVelocity.yVelocity
  ) {
    return;
  }

  broadcastEventToCurrentRoom({
    type: 'thingVelocityChange',
    thingId: thing.id,
    velocity: newVelocity,
  });
}

export function reportCollisionSettingsChangeToPhysicsEngineFromRemote(
  thing: Thing,
  newThing: Thing
) {
  if (
    thing.canCollide === newThing.canCollide &&
    thing.canMove === newThing.canMove &&
    thing.density === newThing.density
  ) {
    return;
  }

  broadcastEventToCurrentRoom({ type: 'thingCollisionSettingsChange' });
}

export function createPhysicsBodiesForCreatedThings(
  things: Array<Thing>,
  physicsEngineIfExists: Engine | null
) {
  if (!isIOs()) {
    return;
  }

  assertTruthy(physicsEngineIfExists);

  const thingIds = things.map((t) => t.id);
  const bodyIds = physicsEngineIfExists.world.bodies.map((b) => b.id);

  const createdThingIds = difference(thingIds, bodyIds);

  createdThingIds.forEach((thingId: number) => {
    addBodyForThing(
      things.find((t) => t.id === thingId)!,
      physicsEngineIfExists
    );
  });
}

export function deletePhysicsBodiesForDeletedThings(
  things: Array<Thing>,
  physicsEngineIfExists: Engine | null
) {
  if (!isIOs()) {
    return;
  }

  assertTruthy(physicsEngineIfExists);

  const thingIds = things.map((t) => t.id);
  const bodyIds = physicsEngineIfExists.world.bodies.map((b) => b.id);

  const thingsToDelete = difference(bodyIds, thingIds);

  thingsToDelete.forEach((thingId: number) => {
    Composite.remove(
      physicsEngineIfExists.world,
      getBodyById(physicsEngineIfExists, thingId)
    );
  });
}

export function reportThingChangesToPhysicsEngineFromLocal(
  physicsEngine: Engine,
  oldThing: Thing,
  newThing: Thing
) {
  reportThingSizeChangeToPhysicsEngineFromLocal(
    physicsEngine,
    oldThing,
    newThing
  );
  reportThingMoveToPhysicsEngineFromLocal(physicsEngine, oldThing, newThing);
  reportThingVelocityChangeToPhysicsEngineFromLocal(
    physicsEngine,
    oldThing,
    newThing
  );
  reportThingSizeChangeToPhysicsEngineFromLocal(
    physicsEngine,
    oldThing,
    newThing
  );
}

function reportThingMoveToPhysicsEngineFromLocal(
  physicsEngine: Engine,
  thing: Thing,
  newPosition: Coordinates
) {
  if (thing.x === newPosition.x && thing.y === newPosition.y) {
    return;
  }

  updateBodyPositionAfterThingMove(physicsEngine, thing.id, newPosition, {
    width: thing.width,
    height: thing.height,
  });
}

export function reportThingVelocityChangeToPhysicsEngineFromLocal(
  physicsEngine: Engine,
  oldThing: Thing,
  newVelocity: { xVelocity: number; yVelocity: number }
) {
  const body = getBodyById(physicsEngine, oldThing.id);

  const newXVelocity =
    oldThing.xVelocity !== newVelocity.xVelocity &&
    newVelocity.xVelocity !== body.velocity.x
      ? newVelocity.xVelocity
      : null;
  const newYVelocity =
    oldThing.yVelocity !== newVelocity.yVelocity &&
    newVelocity.yVelocity !== body.velocity.y
      ? newVelocity.yVelocity
      : null;

  // Avoid setting velocity unless it's actually changed to avoid disturbing
  // the physics engine
  if (newXVelocity === null && newYVelocity === null) {
    return;
  }

  Body.setVelocity(body, {
    x: newXVelocity !== null ? newXVelocity : body.velocity.x,
    y: newYVelocity !== null ? newYVelocity : body.velocity.y,
  });
}

export function reportThingSizeChangeToPhysicsEngineFromLocal(
  physicsEngine: Engine,
  oldThing: Thing,
  newSize: { width: number; height: number }
) {
  if (oldThing.width === newSize.width && oldThing.height === newSize.height) {
    return;
  }

  const body = getBodyById(physicsEngine, oldThing.id);

  Body.scale(
    body,
    newSize.width / oldThing.width,
    newSize.height / oldThing.height
  );
}

export function updateThingAfterPhysicsBodyChange(
  physicsEngine: Engine,
  thing: Thing
) {
  const body = getBodyByIdIfExists(physicsEngine, thing.id);
  if (!body) {
    return;
  }

  const thingPosition = bodyToThingCoordinates(body);
  thing.x = thingPosition.x;
  thing.y = thingPosition.y;
  thing.xVelocity = body.velocity.x;
  thing.yVelocity = body.velocity.y;
}

function thingToBodyCoordinates(
  thingCoordinates: Coordinates,
  thingDimensions: Dimensions
) {
  return {
    x: thingCoordinates.x + thingDimensions.width / 2,
    y: thingCoordinates.y + thingDimensions.height / 2,
  };
}

function bodyToThingCoordinates(body: Body) {
  return {
    x: body.position.x - (body.bounds.max.x - body.bounds.min.x) / 2,
    y: body.position.y - (body.bounds.max.y - body.bounds.min.y) / 2,
  };
}

export function getBodyById(physicsEngine: Engine, id: number): Body {
  const body = physicsEngine.world.bodies.find((body: Body) => body.id === id);

  assertTruthy(body, `Could not find body with id ${id}`);

  return body;
}

export function getBodyByIdIfExists(
  physicsEngine: Engine,
  id: number
): Body | null {
  const body = physicsEngine.world.bodies.find((body: Body) => body.id === id);

  if (!body) {
    return null;
  }

  return body;
}

let nextBodyThingId = 0;
export function getBodyThingId() {
  return nextBodyThingId++;
}
