import React from 'react';
import { useReducer, useContext } from 'react';
import { EditorOnlyStateAction } from './dispatch';
import { compress, decompress } from 'lz-string';
import { EditorOnlyState, assertTruthy } from './types';

let localStorageKey: string | null = null;

export function initializeEditorOnlyState(localStorageKeyToSet: string): void {
  assertTruthy(!localStorageKey || localStorageKey === localStorageKeyToSet);
  localStorageKey = localStorageKeyToSet;
}

export function getLocalStorageKey(): string {
  assertTruthy(localStorageKey);

  return localStorageKey;
}

export function useEditorOnlyStateContext(): {
  editorAppState: EditorOnlyState;
  dispatchEditorAppState: React.Dispatch<EditorOnlyStateAction>;
} {
  const context = useContext(
    getEditorOnlyStateContext<EditorOnlyState, EditorOnlyStateAction>()
  );

  if (!context) {
    throw new Error('Context has not been instantiated');
  }

  return context;
}

export function useEditorOnlyState(
  dispatchFn: (
    editorAppState: EditorOnlyState,
    action: EditorOnlyStateAction
  ) => EditorOnlyState,
  loadedState: EditorOnlyState
): {
  editorAppState: EditorOnlyState;
  dispatchEditorAppState: React.Dispatch<EditorOnlyStateAction>;
  EditorOnlyStateContext: React.Context<{
    editorAppState: EditorOnlyState;
    dispatchEditorAppState: React.Dispatch<EditorOnlyStateAction>;
  }>;
} {
  const [editorAppState, dispatchEditorAppState] = useReducer(
    (state: EditorOnlyState, action: EditorOnlyStateAction) => {
      const newState = dispatchFn(state, action);

      _persistEditorOnlyState(newState);

      return newState;
    },
    loadedState
  );

  globalizeEditorOnlyState(window, editorAppState, dispatchEditorAppState);

  return {
    editorAppState,
    dispatchEditorAppState,
    EditorOnlyStateContext: getEditorOnlyStateContext<
      EditorOnlyState,
      EditorOnlyStateAction
    >(),
  };
}

let EditorOnlyStateContext: unknown = null;

export function getEditorOnlyStateContext<
  EditorOnlyState,
  EditorOnlyStateAction
>(): React.Context<{
  editorAppState: EditorOnlyState;
  dispatchEditorAppState: React.Dispatch<EditorOnlyStateAction>;
}> {
  if (!EditorOnlyStateContext) {
    EditorOnlyStateContext = React.createContext<{
      editorAppState: EditorOnlyState;
      dispatchEditorAppState: React.Dispatch<EditorOnlyStateAction>;
    } | null>(null);
  }

  return EditorOnlyStateContext as React.Context<{
    editorAppState: EditorOnlyState;
    dispatchEditorAppState: React.Dispatch<EditorOnlyStateAction>;
  }>;
}

function _persistEditorOnlyState(state: EditorOnlyState) {
  window.localStorage.setItem(
    getLocalStorageKey(),
    compress(JSON.stringify(state))
  );
}

export function _loadEditorOnlyState(): EditorOnlyState | null {
  const dataString = window.localStorage.getItem(getLocalStorageKey());

  if (dataString === null) {
    return null;
  }

  const decompressedDataString = decompress(dataString);

  assertTruthy(decompressedDataString !== null);

  return JSON.parse(decompressedDataString);
}

function globalizeEditorOnlyState(
  window: Window,
  state: EditorOnlyState,
  dispatch: React.Dispatch<EditorOnlyStateAction>
) {
  // eslint-disable-next-line
  (window as any).editorOnlyState = {
    state,
    dispatch,
    decompress: () => {
      return _loadEditorOnlyState();
    },
    update: (mutator: (currentState: EditorOnlyState) => void) => {
      const currentState = _loadEditorOnlyState();
      assertTruthy(currentState);
      mutator(currentState);
      _persistEditorOnlyState(currentState);
    },
    destroy: () => {
      localStorage.removeItem(getLocalStorageKey());
      window.location.reload();
    },
  };
}

export function getDispatch(): React.Dispatch<EditorOnlyStateAction> {
  // @ts-ignore:next-line
  return window.substantiate.dispatch;
}
