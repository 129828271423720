import './App.css';
import { Tab } from './types';
import { capitalize } from 'lodash';
import { Tablist, Tab as EvergreenTab } from 'evergreen-ui';
import { useEditorOnlyStateContext } from './substantiate';

export function ClickableTabs({ selectedTab }: { selectedTab: Tab }) {
  return (
    <div className="flex items-start border-box" style={{ height: 44 }}>
      <Tablist>
        {Object.values(Tab).map((tab) => (
          <ClickableTab key={tab} tab={tab} selectedTab={selectedTab} />
        ))}
      </Tablist>
    </div>
  );
}

export function getTabIcon(tab: Tab) {
  const tabIcon = {
    [Tab.THING]: '👾',
    [Tab.SPEC]: '📄',
    [Tab.CODE]: '🖥️',
    [Tab.SHARE]: '📤',
    [Tab.HELP]: '💡',
  }[tab];

  return tabIcon;
}

function ClickableTab({ tab, selectedTab }: { tab: Tab; selectedTab: Tab }) {
  const { dispatchEditorAppState } = useEditorOnlyStateContext();

  return (
    <EvergreenTab
      className=""
      aria-controls={`panel-${tab}`}
      isSelected={selectedTab === tab}
      size={'medium'}
      height={44}
      onSelect={() =>
        dispatchEditorAppState({ type: 'updateSelectedTab', selectedTab: tab })
      }
      key={tab}
    >
      {getTabIcon(tab)} {capitalize(tab)}
    </EvergreenTab>
  );
}
