import { COLORS, Coordinates, DrawingTool, Thing, assertTruthy } from './types';
import { useDraggableThing } from './useDraggableThing';
import {
  reportThingMoveToPhysicsEngineFromRemote,
  reportThingSizeChangeToPhysicsEngineFromRemote,
  reportThingVelocityChangeToPhysicsEngineFromRemote,
} from './usePhysicsEngineIfIOs';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useEditorOnlyStateContext } from './substantiate';
import {
  imageUpdate,
  thingUpdate,
  updateIsPaused,
  useAppDispatch,
  useAppSelector,
} from './useData';
import { noop } from 'lodash';
import { DrawingCanvas } from './DrawingCanvas';
import { useLoadedImage } from './useLoadedImage';
import { useOnCreateThing } from './useOnCreateThing';
import { useOnDuplicateThing } from './useOnDuplicateThing';

const GRID_INTERVAL = 3;
const SELECTION_BORDER_SIZE = 3;

export function getGridAlignedCoordinate(
  coordinate: number,
  screenGlobalTopLeftOnAxis: number
): number {
  return (
    Math.ceil(coordinate / GRID_INTERVAL) * GRID_INTERVAL -
    GRID_INTERVAL / 2 -
    screenGlobalTopLeftOnAxis
  );
}

function getDragOrigin(
  thing: Thing,
  things: Array<Thing>,
  origin: Coordinates
): Coordinates {
  if (thing.parentId === null) {
    return origin;
  }

  const parentThing = things.find((t) => t.id === thing.parentId);
  assertTruthy(parentThing);

  return getDragOrigin(parentThing, things, {
    x: origin.x + parentThing.x,
    y: origin.y + parentThing.y,
  });
}

export function ThingWrapperForEditorScreen({
  children,
  thingIndex,
  thing,
  screenGlobalTopLeft,
}: {
  children: React.ReactNode;
  thingIndex: number;
  thing: Thing;
  screenGlobalTopLeft: Coordinates;
}) {
  const things = useAppSelector((state) => state.things);
  const isPaused = useAppSelector((state) => state.isPaused);
  const images = useAppSelector((state) => state.images);
  const [wasPausedWhenDragStarted, updateWasPausedWhenDragStarted] =
    useState(false);
  const dispatch = useAppDispatch();

  const [didMouseGoDownAndNotMove, setDidMouseGoDownAndNotMove] =
    useState(false);

  const { editorAppState, dispatchEditorAppState } =
    useEditorOnlyStateContext();
  const { selectedThingsIndices } = editorAppState;

  const isSelected = selectedThingsIndices.includes(thingIndex);

  const [isHoveringOverThing, setIsHoveringOverThing] = useState(false);

  const [areEditingImage, setAreEditingImage] = useState(false);

  const onCreateThing = useOnCreateThing(things);
  const onDuplicateThing = useOnDuplicateThing(things, onCreateThing);

  const selectThing = useCallback(
    (e) => {
      const newSelectedThingIndices = e.shiftKey
        ? selectedThingsIndices.includes(thingIndex)
          ? selectedThingsIndices.filter((i) => i !== thingIndex)
          : selectedThingsIndices.concat(thingIndex)
        : [thingIndex];

      dispatchEditorAppState({
        type: 'updateSelectedThingsIndices',
        selectedThingsIndices: newSelectedThingIndices,
      });
    },
    [dispatchEditorAppState, selectedThingsIndices, thingIndex]
  );

  const [, dragAndSelectHandlers] = useDraggableThing({
    origin: getDragOrigin(thing, things, { x: 0, y: 0 }),
    onStart: useCallback(
      (e) => {
        if (areEditingImage) {
          return;
        }

        if (e.altKey) {
          onDuplicateThing({ ...thing });
        }

        updateWasPausedWhenDragStarted(isPaused);
        if (!isPaused) {
          dispatch(updateIsPaused({ isPaused: true }));
        }

        setDidMouseGoDownAndNotMove(true);
      },
      [areEditingImage, dispatch, isPaused, onDuplicateThing, thing]
    ),
    onMove: ({ x, y }: { x: number; y: number }) => {
      if (areEditingImage) {
        return;
      }

      const position = {
        x: getGridAlignedCoordinate(x, screenGlobalTopLeft.x),
        y: getGridAlignedCoordinate(y, screenGlobalTopLeft.y),
      };

      reportThingMoveToPhysicsEngineFromRemote(thing, position);

      dispatch(thingUpdate({ index: thingIndex, update: position }));

      setDidMouseGoDownAndNotMove(false);
    },
    onStop: useCallback(
      (e) => {
        if (areEditingImage) {
          return;
        }

        thingUpdate({
          index: thingIndex,
          update: { xVelocity: 0, yVelocity: 0 },
        });
        reportThingVelocityChangeToPhysicsEngineFromRemote(thing, {
          xVelocity: 0,
          yVelocity: 0,
        });

        if (!wasPausedWhenDragStarted) {
          dispatch(updateIsPaused({ isPaused: false }));
        }

        if (didMouseGoDownAndNotMove) {
          selectThing(e);
        }
      },
      [
        areEditingImage,
        didMouseGoDownAndNotMove,
        dispatch,
        selectThing,
        thing,
        thingIndex,
        wasPausedWhenDragStarted,
      ]
    ),
  });

  const shouldRenderEditor = isSelected && areEditingImage;

  const imageResult = useLoadedImage({
    dataUrlIfExists: images[thing.id] ?? null,
    // Don't want to be reloading during image editing - will mess up mouse
    // events.  But do want to reload when not editing so that we have the
    // latest image cached ready to send in as existingImage next we go into
    // edit mode
    shouldReloadOnUrlChange: !areEditingImage,
  });

  return (
    <>
      <div
        onMouseOver={() => {
          setIsHoveringOverThing(true);
        }}
        onMouseOut={() => {
          setIsHoveringOverThing(false);
        }}
        {...dragAndSelectHandlers}
        // We handle thing selecting in the drag code. Here, we need to stop a
        // click (which is fired alongside the mouse down+up for drag and selection)
        // propagating up to the screen background and causing a de-select
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          outline: isSelected
            ? `#4444ff dashed ${SELECTION_BORDER_SIZE}px`
            : `#cccccc solid 1px`,
        }}
        className={classNames(
          'width-full height-full rounded',
          areEditingImage ? 'default' : 'grab'
        )}
      >
        <ImageOrDrawingCanvas
          shouldRenderEditor={shouldRenderEditor}
          thing={thing}
          imageResult={imageResult}
        >
          {children}
        </ImageOrDrawingCanvas>

        {!shouldRenderEditor && (
          <ResizeHandle
            thing={thing}
            thingIndex={thingIndex}
            isHoveringOverThing={isHoveringOverThing}
          />
        )}

        {isSelected && (
          <EditImageButton
            thing={thing}
            areEditingImage={areEditingImage}
            setAreEditingImage={setAreEditingImage}
          />
        )}

        <VelocityLine thing={thing} />
      </div>

      {shouldRenderEditor && <EditImageToolbar />}
    </>
  );
}

function ImageOrDrawingCanvas({
  children,
  shouldRenderEditor,
  thing,
  imageResult,
}: {
  children: React.ReactNode;
  shouldRenderEditor: boolean;
  thing: Thing;
  imageResult: ReturnType<typeof useLoadedImage>;
}) {
  const dispatch = useAppDispatch();

  if (
    shouldRenderEditor &&
    (imageResult.type === 'loaded' || imageResult.type === 'no-image')
  ) {
    return (
      <DrawingCanvas
        dimensions={thing}
        existingImage={imageResult.type === 'loaded' ? imageResult.image : null}
        onChange={(imageData: string) => {
          dispatch(
            imageUpdate({ imageIndex: thing.imageId, image: imageData })
          );
        }}
      />
    );
  }

  return <>{children}</>;
}

function EditImageButton({
  thing,
  areEditingImage,
  setAreEditingImage,
}: {
  thing: Thing;
  areEditingImage: boolean;
  setAreEditingImage: (areEditingImage: boolean) => void;
}) {
  const fontSize = 20;

  useEffect(() => {
    return () => {
      setAreEditingImage(false);
    };
  }, [setAreEditingImage]);

  return (
    <div
      style={{
        position: 'absolute',
        left: thing.width + 2,
        top: -32,
        width: 30,
        height: 30,
        fontSize,
      }}
      onMouseDown={(e) => {
        // Prevent mouse down from initiating drag
        e.stopPropagation();

        setAreEditingImage(!areEditingImage);
      }}
      onClick={(e) => {
        // Prevent click from selecting thing
        e.stopPropagation();
      }}
      className={classNames(
        'flex justify-center items-center border-box pointer no-user-select rounded pointer border border-gray-light1',
        areEditingImage ? 'yellow' : 'gray-light2'
      )}
    >
      🎨
    </div>
  );
}

function EditImageToolbar() {
  const { editorAppState, dispatchEditorAppState } =
    useEditorOnlyStateContext();
  const { selectedColor, selectedTool } = editorAppState;

  const SWATCH_SIZE = 30;
  const SWATCH_GAP = 8;

  return (
    <div
      className="py1 gray-light2 rounded no-user-select flex"
      style={{
        marginTop: SELECTION_BORDER_SIZE,
        marginLeft: -SELECTION_BORDER_SIZE,
        width: (COLORS.length + 1) * (SWATCH_SIZE + SWATCH_GAP) + SWATCH_GAP,
      }}
    >
      {COLORS.map((color) => {
        return (
          <div
            key={color}
            className={classNames('ml1 pointer rounded', {
              'border border-thick border-box':
                selectedTool === DrawingTool.PEN && color === selectedColor,
            })}
            style={{
              backgroundColor: color,
              width: SWATCH_SIZE,
              height: SWATCH_SIZE,
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatchEditorAppState({
                type: 'updateSelectedColor',
                selectedColor: color,
              });
            }}
          ></div>
        );
      }).concat(
        <div
          key="eraser"
          className={classNames(
            'ml1 pointer rounded flex justify-center items-center',
            {
              'border border-thick border-box':
                selectedTool === DrawingTool.ERASER,
            }
          )}
          style={{
            width: SWATCH_SIZE,
            height: SWATCH_SIZE,
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatchEditorAppState({
              type: 'updateSelectedTool',
              selectedTool: DrawingTool.ERASER,
            });
          }}
        >
          🧽
        </div>
      )}
    </div>
  );
}

function ResizeHandle({
  thing,
  thingIndex,
  isHoveringOverThing,
}: {
  thing: Thing;
  thingIndex: number;
  isHoveringOverThing: boolean;
}) {
  const dispatch = useAppDispatch();

  const [isDragging, resizingHandlers] = useDraggableThing({
    origin: {
      x: thing.x + thing.width + 5,
      y: thing.y + thing.height + 44 + 5,
    },
    onStart: noop,
    onMove: ({ x, y }: { x: number; y: number }) => {
      const newSize = {
        width: thing.width + x,
        height: thing.height + y,
      };

      if (newSize.width < 0 || newSize.height < 0) {
        return;
      }

      dispatch(
        thingUpdate({
          index: thingIndex,
          update: newSize,
        })
      );

      reportThingSizeChangeToPhysicsEngineFromRemote(thing, newSize);
    },
    onStop: noop,
  });

  const shouldShow = isDragging || isHoveringOverThing;

  return (
    <div
      style={{
        position: 'absolute',
        left: thing.width - 10,
        top: thing.height - 10,
        width: 15,
        height: 15,
        cursor: 'se-resize',
        zIndex: 10,
      }}
      className="border-box"
      {...resizingHandlers}
    >
      <div
        style={{
          position: 'absolute',
          width: 4,
          height: 11,
          top: 0,
          left: 7,
        }}
        className={classNames({ dark: shouldShow }, 'border-box rounded')}
      />
      <div
        style={{
          position: 'absolute',
          width: 11,
          height: 4,
          top: 7,
          left: 0,
        }}
        className={classNames({ dark: shouldShow }, 'border-box rounded')}
      />
    </div>
  );
}

function VelocityLine({ thing }: { thing: Thing }) {
  if ((!thing.xVelocity && !thing.yVelocity) || !thing.canMove) {
    return null;
  }

  const thingCenter = { x: thing.width * 1.5, y: thing.height * 1.5 };

  const velocityLine = {
    start: { x: thingCenter.x, y: thingCenter.y },
    end: {
      x: thingCenter.x + thing.xVelocity * 10,
      y: thingCenter.y + thing.yVelocity * 10,
    },
  };

  return (
    <svg
      style={{
        position: 'absolute',
        left: -thing.width,
        top: -thing.height,
        width: thing.width * 3,
        height: thing.height * 3,
      }}
    >
      <line
        strokeWidth={3}
        x1={velocityLine.start.x}
        y1={velocityLine.start.y}
        x2={velocityLine.end.x}
        y2={velocityLine.end.y}
        stroke="black"
      />
    </svg>
  );
}
