import { useCallback, useEffect } from 'react';
import { Thing, ThingUpdate } from './types';
import {
  thingCreate,
  updatePreviousThingTypeIndex,
  useAppDispatch,
  useAppSelector,
} from './useData';
import { getUniqueThingType } from './getUniqueThingType';

// TODO: Find better approach.
let nextId = 0;

export function useOnCreateThing(
  things: Array<Thing>
): (update: ThingUpdate) => Thing {
  const previousThingTypeIndex = useAppSelector(
    (state) => state.previousThingTypeIndex
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    // On initial load, Init nextId to first ID after starter things. Then
    // nextId incrementing (further down) will take care of creating unique IDs
    // during this browser load.
    nextId = things.length > 0 ? Math.max(...things.map((t) => t.id)) + 1 : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateThing = useCallback(
    (update: ThingUpdate) => {
      const { nextIndex, thingType } = getUniqueThingType(
        previousThingTypeIndex
      );

      const newThing: Thing = {
        color: '#00aa00',
        textColor: 'white',
        isHidden: false,
        canMove: true,
        canCollide: true,
        density: 0.001,
        width: 40,
        height: 40,
        x: 30,
        y: 30,
        angle: 0,
        xVelocity: 0,
        yVelocity: 0,
        type: thingType,
        parentId: null,
        ...update,
        imageId: nextId,
        id: nextId,
      };

      nextId += 1;

      dispatch(thingCreate({ thing: newThing }));
      dispatch(
        updatePreviousThingTypeIndex({ previousThingTypeIndex: nextIndex })
      );

      // Physics body gets created when things updates on the iOS device

      return newThing;
    },
    [dispatch, previousThingTypeIndex]
  );

  return onCreateThing;
}
