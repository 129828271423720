import { BroadcastOptions } from '@liveblocks/client';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { SharedState } from './useData';

export enum ThingEvent {
  ON_THING_TAP = 'onThingTap',
  ON_WORLD_TAP = 'onWorldTap',
  ON_TICK = 'onTick',
  ON_EVENT = 'onEvent',
  ON_COLLIDE = 'onCollide',
  ON_COLLIDING = 'onColliding',
  ON_UNCOLLIDE = 'onUncollide',
  ON_DRAW = 'onDraw',
}

export type Thing = {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  xVelocity: number;
  yVelocity: number;
  color: string;
  textColor: 'white';
  canMove: boolean;
  canCollide: boolean;
  density: 0.001;
  type: string;
  text?: string;
  angle: number;
  isHidden: boolean;
  parentId: number | null;
  imageId: number;
};

export type ThingKey = keyof Thing;

export type Spec = Array<SpecEntry>;

export type SpecEntry = { selectedThingTypes: Array<string>; text: string };

export type ThingUpdate = { [key in ThingKey]?: any };

export type Api = any;

export enum Tab {
  THING = 'thing',
  SPEC = 'spec',
  CODE = 'code',
  SHARE = 'share',
  HELP = 'help',
}

export type HandlerFn =
  | OnTickHandler
  | OnThingTapHandler
  | OnWorldTapHandler
  | OnCollideHandler
  | OnUncollideHandler
  | OnDrawHandler;

export type ExampleHandlers = {
  [ThingEvent.ON_TICK]: { description: string; fn: OnTickHandler };
  [ThingEvent.ON_THING_TAP]: {
    description: string;
    fn: OnThingTapHandler;
  };
  [ThingEvent.ON_WORLD_TAP]: {
    description: string;
    fn: OnWorldTapHandler;
  };
  [ThingEvent.ON_EVENT]: {
    description: string;
    fn: OnEventHandler;
  };

  [ThingEvent.ON_COLLIDE]: { description: string; fn: OnCollideHandler };
  [ThingEvent.ON_COLLIDING]: { description: string; fn: OnCollidingHandler };
  [ThingEvent.ON_UNCOLLIDE]: {
    description: string;
    fn: OnUncollideHandler;
  };
  [ThingEvent.ON_DRAW]: {
    description: string;
    fn: OnDrawHandler;
  };
};

export type ThingHandler = { code?: string; prompt?: string };

export type HandlerByEventName = {
  [eventName in ThingEvent]?: ThingHandler;
};

export type HandlersByType = { [type: string]: HandlerByEventName };

export type OnTickHandler = (
  this: Thing,
  api: Api,
  things: Array<Thing>
) => void;
export type OnThingTapHandler = (
  this: Thing,
  api: Api,
  things: Array<Thing>
) => void;
export type OnWorldTapHandler = (
  this: Thing,
  api: Api,
  coordinates: Coordinates,
  things: Array<Thing>
) => void;
export type OnEventHandler = (
  this: Thing,
  api: Api,
  event: string,
  data: { [key: string]: any },
  things: Array<Thing>
) => void;
export type OnCollideHandler = (
  this: Thing,
  api: Api,
  otherThing: Thing,
  things: Array<Thing>
) => void;
export type OnCollidingHandler = (
  this: Thing,
  api: Api,
  otherThing: Thing,
  things: Array<Thing>
) => void;
export type OnUncollideHandler = (this: Thing, api: Api) => void;
export type OnDrawHandler = (
  thing: Thing,
  api: Api,
  things: Array<Thing>,
  children: React.ReactNode
) => void;

export type PlayScreenSize = { width: number; height: number };

export type Dimensions = { width: number; height: number };

export type Collision = {
  bodyId: number;
  otherBodyId: number;
};

export type Coordinates = { x: number; y: number };

export type ThingUpdateFn = (index: number, update: ThingUpdate) => void;

export type Json = { [id: string]: Json | Array<Json> | string | number };

export type Db = Json;

export type Watches = {
  [watchId: string]: { location: WatchLocation; color?: string };
};

export type WatchLocation = Coordinates | Thing | { x: number } | { y: number };

export type WatchContext = {
  watches: Watches;
  watch: (coordinates: Coordinates, color?: string) => void;
};

export type RoomEvent =
  | {
      type: 'thingMove';
      thingId: number;
      position: Coordinates;
      dimensions: Dimensions;
    }
  | {
      type: 'thingCreate';
      thingId: number;
    }
  | { type: 'thingCollisionSettingsChange' }
  | {
      type: 'thingVelocityChange';
      thingId: number;
      velocity: { xVelocity: number; yVelocity: number };
    }
  | {
      type: 'thingSizeChange';
      thingId: number;
      newSize: { width: number; height: number };
    };

export type BroadcastFn = (
  event: RoomEvent,
  options?: BroadcastOptions | undefined
) => void;

export type OpenAiFormatMessage = {
  role: 'system' | 'user' | 'assistant';
  content: string;
};

export interface ServerGptQuery {
  openAiApiKey: string;
  messages: Array<OpenAiFormatMessage>;
  temperature: number;
  maxTokens?: number;
  model?: string;
}

export type KeyModifiers = {
  altKey?: true;
  ctrlOrCommand?: true;
  shiftKey?: true;
};

export function exhaustiveGuard(_value: never): never {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(
      _value
    )}`
  );
}

export type Conversation = Array<Message>;

export type Sender = 'user' | 'bot';

export type Message =
  | {
      sender: 'bot';
      text: string;
      isComplete: boolean;
      isError?: true;
    }
  | {
      sender: 'user';
      text: string;
    };

export enum RowsConfigType {
  GROW_WITH_TEXT = 'growWithText',
  FULL_HEIGHT = 'fullHeight',
}

export type RowsConfig =
  | { type: RowsConfigType.GROW_WITH_TEXT; min?: number; max?: number }
  | { type: RowsConfigType.FULL_HEIGHT };

export type Modifiers = {
  shiftKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  altKey: boolean;
};

export enum View {
  PATCH = 'patch',
  FULL = 'full',
}

export enum Artifact {
  SPEC = 'spec',
  CODE = 'code',
}

export type CodeObject = { [key: string]: any };

export type CodeMergeTextEdit = {
  type: 'textEdit';
  eventType: ThingEvent;
  thingType: string;
};

export type CodeMerge =
  | CodeMergeTextEdit
  | { type: 'genAll' } // disallowed
  | { type: 'genSelected'; selectedThingTypes: Array<string> };

export type EditorOnlyState = {
  selectedThingsIndices: Array<number>;
  selectedTab: Tab;
  conversationInput: string;
  specConversation: Conversation;
  codeConversation: Conversation;
  spec: Array<SpecEntry>;
  specAtTimeOfLastCodeGeneration: Array<SpecEntry>;
  code: string;
  codeAtTimeOfLastRun: string;
  indexOfCodeMessageBeingViewed: number | null;
  codeEditingSelectedThingTypeAndEventTypeIfExists: CodeEditingSelectedThingTypeAndEventType | null;
  selectedColor: string;
  selectedTool: DrawingTool;
};

export const Fps = {
  one: 1,
  two: 2,
  ten: 10,
  sixty: 60,
};

export enum Runtime {
  PLAYER = 'player',
  SIMULATOR = 'simulator',
  EDITOR = 'editor',
}

export type CodeEditingSelectedThingTypeAndEventType = {
  thingType: string;
  eventType: ThingEvent;
};

export type CodeEditingSelectedThingTypeAndIfExistsEventType = {
  thingType: string;
  eventType: ThingEvent | null;
};

export type LogLine = {
  type: 'log' | 'error';
  args: Array<unknown>;
  date: number;
  thingType: string;
  eventType: ThingEvent;
};

export type AppDispatch = ThunkDispatch<SharedState, undefined, AnyAction> &
  Dispatch<AnyAction>;

export function assertHasProperty(
  obj: unknown,
  property: keyof any
): asserts obj is Object & Record<typeof property, unknown> {
  if (!obj || typeof obj !== 'object' || !(property in obj)) {
    throw new Error(`Property ${String(property)} is not in object`);
  }
}

export const COLORS = [
  '#8193A8',
  '#796DCB',
  '#6DA0CB',
  '#5BB69F',
  '#99BF6B',
  '#A76AB9',
  '#BB67A2',
  '#C55D83',
  '#D35E4C',
  '#E18C43',
  '#E1B040',
];

export enum DrawingTool {
  PEN = 'pen',
  ERASER = 'eraser',
}

export function assertTruthy(value: unknown, message?: string): asserts value {
  if (
    value === false ||
    value === null ||
    value === undefined ||
    value === ''
  ) {
    throw new Error(`Value not truthy${message ? `: ${message}` : ''}`);
  }
}
