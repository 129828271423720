import { getConversation } from './useData';
import { Api, Artifact, View } from './types';
import { CodePanel } from './CodePanel';
import { useEditorOnlyStateContext } from './substantiate';

export function CodeTab({
  onRunCode,
  selectedThingTypes,
  api,
  codeView,
}: {
  onRunCode: (code: string) => void;
  selectedThingTypes: Array<string>;
  api: Api;
  codeView: View;
}) {
  const { editorAppState } = useEditorOnlyStateContext();
  const conversation = getConversation(editorAppState, Artifact.CODE);
  const { indexOfCodeMessageBeingViewed } = editorAppState;

  return (
    <div className="flex height-full">
      <CodePanel
        conversation={conversation}
        indexOfCodeMessageBeingViewed={indexOfCodeMessageBeingViewed}
        onRunCode={onRunCode}
        selectedThingTypes={selectedThingTypes}
        api={api}
        codeView={codeView}
      />
    </div>
  );
}
