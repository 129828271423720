import { Engine } from 'matter-js';
import { isIOs } from './isIOs';
import { ThingWrapperForEditorScreenOrPlayScreen } from './ThingWrapperForEditorScreenOrPlayScreen';
import { Api, Coordinates, ThingEvent, Thing } from './types';
import { Watches } from './Watches';
import { RenderedThing } from './RenderedThing';
import { INVALID_MESSAGE, runDrawThingHandler } from './thingHandlers';
import { ErrorBoundary } from './ErrorBoundary';
import { useAppSelector } from './useData';

export function RunningGame({
  physicsEngineIfExists,
  screenGlobalTopLeft,
  api,
}: {
  physicsEngineIfExists: Engine | null;
  screenGlobalTopLeft: Coordinates | null;
  api: Api;
}) {
  const things = useAppSelector((state) => state.things);

  return (
    <>
      {things.map((thing, i) => {
        return (
          <RunningGameThing
            key={i}
            physicsEngineIfExists={physicsEngineIfExists}
            screenGlobalTopLeft={screenGlobalTopLeft}
            api={api}
            thing={thing}
            thingIndex={i}
            isChild={false}
            things={things}
          />
        );
      })}

      {!isIOs() && <Watches />}
    </>
  );
}

function RunningGameThing({
  physicsEngineIfExists,
  screenGlobalTopLeft,
  api,
  thing,
  thingIndex,
  isChild,
  things,
}: {
  physicsEngineIfExists: Engine | null;
  screenGlobalTopLeft: Coordinates | null;
  api: Api;
  thing: Thing;
  thingIndex: number;
  isChild: boolean;
  things: Array<Thing>;
}) {
  if ((thing.isHidden && isIOs()) || (thing.parentId !== null && !isChild)) {
    return null;
  }

  const children = things.filter((t: Thing) => t.parentId === thing.id);

  return (
    <div
      key={thingIndex}
      style={{
        position: 'absolute',
        left:
          thing.x +
          (!isChild && screenGlobalTopLeft ? screenGlobalTopLeft.x + 1 : 0),
        top:
          thing.y +
          (!isChild && screenGlobalTopLeft ? screenGlobalTopLeft.y + 1 : 0),
        width: thing.width,
        height: thing.height,
        zIndex: 10,
      }}
    >
      <ThingWrapperForEditorScreenOrPlayScreen
        physicsEngineIfExists={physicsEngineIfExists}
        thing={thing}
        thingIndex={thingIndex}
        things={things}
        screenGlobalTopLeft={screenGlobalTopLeft}
        api={api}
      >
        <ErrorBoundary FallbackComponent={(e) => <>{INVALID_MESSAGE}</>}>
          <RenderedOrGenericThing thing={thing} api={api} things={things}>
            {children.length > 0
              ? children.map((child) => {
                  const childIndex = things.findIndex((t) => t.id === child.id);

                  return (
                    <RunningGameThing
                      key={child.id}
                      physicsEngineIfExists={physicsEngineIfExists}
                      screenGlobalTopLeft={screenGlobalTopLeft}
                      isChild={true}
                      api={api}
                      thing={child}
                      thingIndex={childIndex}
                      things={things}
                    />
                  );
                })
              : null}
          </RenderedOrGenericThing>
        </ErrorBoundary>
      </ThingWrapperForEditorScreenOrPlayScreen>
    </div>
  );
}

export function RenderedOrGenericThing({
  thing,
  api,
  things,
  children,
}: {
  thing: Thing;
  api: Api;
  things: Array<Thing>;
  children: React.ReactNode | null;
}) {
  const handlersByType = useAppSelector((state) => state.handlersByType);

  if (handlersByType[thing.type]?.onDraw) {
    const drawOutput = runDrawThingHandler(
      handlersByType,
      thing,
      ThingEvent.ON_DRAW,
      [api, things, children]
    );

    // ErrorBoundary doesn't catch errors like returning an object or
    // undefined, so catch them manually

    if (!drawOutput) {
      return <>{INVALID_MESSAGE}</>;
    }

    if (typeof drawOutput === 'object' && '$$typeof' in drawOutput) {
      return <>{drawOutput}</>;
    }

    return <>{INVALID_MESSAGE}</>;
  }

  return <RenderedThing thing={thing}>{children}</RenderedThing>;
}
