import { ThingRenderedAsIcon } from './ThingRenderedAsIcon';
import { Api, Thing } from './types';
import { getSelectedThings } from './useData';

export function SelectedThingList({
  selectedThingsIndices,
  things,
  api,
}: {
  selectedThingsIndices: Array<number>;
  things: Array<Thing>;
  api: Api;
}) {
  const selectedThings = getSelectedThings(things, selectedThingsIndices);

  return (
    <div className="flex items-center stronger">
      {selectedThings.length > 0
        ? selectedThings.map((thing) => {
            return (
              <div key={thing.id} className="flex">
                <div className="mr1">
                  <ThingRenderedAsIcon
                    thing={thing}
                    api={api}
                    sizeInPixels={20}
                  />
                </div>

                <div className="mr1">{thing.type}</div>
              </div>
            );
          })
        : 'Nothing selected'}
    </div>
  );
}
