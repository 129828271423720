import {
  Coordinates,
  ExampleHandlers,
  HandlersByType,
  ThingEvent,
  Thing,
  assertTruthy,
  assertHasProperty,
} from './types';
import { reportThingChangesToPhysicsEngineFromLocal } from './usePhysicsEngineIfIOs';
import { Engine } from 'matter-js';
import * as babel from '@babel/standalone';
import React from 'react';
import { AppDispatch, logLineAdd } from './useData';
import { ConsoleWrapper } from './ConsoleWrapper';

export const INVALID_MESSAGE = 'Invalid';

// Changes here must be copied to prompts.tsx for now.  These defs are used to
// derive a description of the handler API for the LLM. Prod minifies this file
// so the descriptions are hard to understand. Idea: derive the description on
// dev save to a file.
export const exampleHandlers: ExampleHandlers = {
  onTick: {
    description:
      "Called every tick of the game for this thing. Must run very quickly so it doesn't block.",
    fn: function (this: Thing, api: any, things: Array<Thing>) {
      const buzz = things.find((thing) => {
        return thing.type === 'buzz';
      });

      const velocity = api.unitVector(api.vectorBetween(this, buzz));
      this.xVelocity = velocity.x * 2;
      this.yVelocity = velocity.y * 2;
    },
  },

  onThingTap: {
    description: 'Called when this thing is tapped.',
    fn: function (this: Thing, api: any, things: Array<Thing>) {},
  },

  onWorldTap: {
    description:
      "Called when the screen is tapped. 'coordinates' contains the location of the tap.",
    fn: function (
      this: Thing,
      api: any,
      coordinates: Coordinates,
      things: Array<Thing>
    ) {
      const velocity = api.unitVector(api.vectorBetween(this, coordinates));

      this.xVelocity = velocity.x * 2;
      this.yVelocity = velocity.y * 2;
    },
  },

  onEvent: {
    description: 'Called when an event is sent to this thing.',
    fn: function (
      this: Thing,
      api: any,
      event: string,
      data: { [key: string]: any },
      things: Array<Thing>
    ) {},
  },

  onCollide: {
    description: 'Called when this thing collides with another thing.',
    fn: function (
      this: Thing,
      api: any,
      otherThing: Thing,
      things: Array<Thing>
    ) {},
  },

  onColliding: {
    description:
      'Called every tick when this thing is colliding with otherThing.',
    fn: function (
      this: Thing,
      api: any,
      otherThing: Thing,
      things: Array<Thing>
    ) {},
  },

  onUncollide: {
    description: 'Called when this thing stops colliding with another thing.',
    fn: function (this: Thing, api: any) {},
  },

  onDraw: {
    description:
      "Called to draw this thing. Should be a React function component. The output SVG should be the same size as 'thing'.  You can assume the SVG has already been positiioned in the correct x,y position in the scene so the top left of the SVG is 0,0.",
    fn: function (
      thing: Thing,
      api: any,
      things: Array<Thing>,
      children: React.ReactNode
    ) {},
  },
};

// When you call this, make sure to pass newThings and send to server
export function runUpdateThingHandler(
  dispatch: AppDispatch,
  physicsEngine: Engine,
  handlersByType: HandlersByType,
  thing: Thing,
  handlerName: ThingEvent,
  handlerArguments: Array<any>
) {
  const handler = handlersByType[thing.type]?.[handlerName]?.code;

  if (!handler) {
    return;
  }

  const f = compileHandlerCodeToFunction(handler, handlerName, thing.type);

  if (!f) {
    console.error(`Could not compile ${handlerName} handler for ${thing.type}`);
    return;
  }

  const oldThing = { ...thing };
  const consoleWrapper = new ConsoleWrapper(dispatch, thing.type, handlerName);
  consoleWrapper.wrap();

  try {
    f.call(thing, ...handlerArguments);
    consoleWrapper.unwrap();
  } catch (e) {
    assertHasProperty(e, 'message');

    dispatch(
      logLineAdd({
        type: 'error',
        args: [`${handlerName} handler: ${e.message}`],
        date: Date.now(),
        thingType: thing.type,
        eventType: handlerName,
      })
    );

    consoleWrapper.unwrap();
    console.error(`${handlerName} handler:`, e);
  }

  reportThingChangesToPhysicsEngineFromLocal(physicsEngine, oldThing, thing);
}

export function compileHandlerCodeToFunction(
  code: string,
  handlerName: ThingEvent,
  thingType: string
): Function | null {
  switch (handlerName) {
    case ThingEvent.ON_DRAW: {
      let babelCode;
      try {
        babelCode = babel.transform(code, {
          presets: ['react'],
        }).code;
      } catch (e) {
        console.log(e);
        return null;
      }

      assertTruthy(babelCode);

      const nonStrictBabelCode = babelCode.replace('"use strict";', '').trim();

      const f = instantiateHandler(
        nonStrictBabelCode,
        thingType,
        handlerName,
        getHandlerParameterNames(handlerName).concat(['React'])
      );

      return f;
    }
    case ThingEvent.ON_THING_TAP:
    case ThingEvent.ON_WORLD_TAP:
    case ThingEvent.ON_TICK:
    case ThingEvent.ON_EVENT:
    case ThingEvent.ON_COLLIDE:
    case ThingEvent.ON_COLLIDING:
    case ThingEvent.ON_UNCOLLIDE: {
      const f = instantiateHandler(
        code,
        thingType,
        handlerName,
        getHandlerParameterNames(handlerName)
      );

      return f;
    }
    default:
      throw new Error(`Unknown handler name ${handlerName}`);
  }
}

export function runDrawThingHandler(
  handlersByType: HandlersByType,
  thing: Thing,
  handlerName: ThingEvent,
  handlerArguments: Array<any>
): string {
  const code = handlersByType[thing.type]?.[handlerName]?.code;

  if (!code) {
    return INVALID_MESSAGE;
  }

  const f = compileHandlerCodeToFunction(code, handlerName, thing.type);

  if (!f) {
    return INVALID_MESSAGE;
  }

  try {
    const returnValue = f.call(
      null,
      thing,
      ...handlerArguments,
      React
    ) as string;
    return returnValue;
  } catch (e) {
    console.error(`${handlerName} handler:`, e);
    return INVALID_MESSAGE;
  }
}

// TODO: Fix this with same fix as problem outlined in comment at top of file
function getHandlerParameterNames(handlerName: ThingEvent): Array<string> {
  return {
    onThingTap: ['api', 'things'],
    onWorldTap: ['api', 'coordinates', 'things'],
    onTick: ['api', 'things'],
    onEvent: ['api', 'event', 'data', 'things'],
    onCollide: ['api', 'otherThing', 'things'],
    onColliding: ['api', 'otherThing', 'things'],
    onUncollide: ['api'],
    onDraw: ['thing', 'api', 'things', 'children'],
  }[handlerName];
}

function instantiateHandler(
  code: string,
  thingText: string,
  handlerName: ThingEvent,
  handlerParameterNames: Array<string>
): Function | null {
  const handlerLines: Array<string> = code.split('\n');
  const handlerWithoutFunctionDefinition: string = handlerLines
    .slice(1, handlerLines.length - 1)
    .join('\n');

  try {
    // eslint-disable-next-line no-new-func
    const f = new Function(
      ...handlerParameterNames.concat(handlerWithoutFunctionDefinition)
    );

    return f;
  } catch (e) {
    return null;
  }
}

export function getEventEnglishDescription(eventType: ThingEvent) {
  switch (eventType) {
    case ThingEvent.ON_TICK:
      return 'Tick';
    case ThingEvent.ON_THING_TAP:
      return 'Tap thing';
    case ThingEvent.ON_WORLD_TAP:
      return 'Tap world';
    case ThingEvent.ON_EVENT:
      return 'Event';
    case ThingEvent.ON_COLLIDE:
      return 'Collide';
    case ThingEvent.ON_COLLIDING:
      return 'Colliding';
    case ThingEvent.ON_UNCOLLIDE:
      return 'Uncollide';
    case ThingEvent.ON_DRAW:
      return 'Draw';
    default:
      throw new Error(`Unknown event type ${eventType}`);
  }
}

export function getHandlerStub(event: ThingEvent) {
  return `${getStableHandlerSignature(event)} {\n\n}`;
}

export function getStableHandlerSignature(event: ThingEvent) {
  return {
    [ThingEvent.ON_THING_TAP]: 'function onThingTap(api, things)',
    [ThingEvent.ON_WORLD_TAP]: 'function onWorldTap(api, coordinates, things)',
    [ThingEvent.ON_TICK]: 'function onTick(api, things)',
    [ThingEvent.ON_EVENT]: 'function onEvent(api, event, data, things)',
    [ThingEvent.ON_COLLIDE]: 'function onCollide(api, otherThing, things)',
    [ThingEvent.ON_COLLIDING]: 'function onColliding(api, otherThing, things)',
    [ThingEvent.ON_UNCOLLIDE]: 'function onUncollide(api)',
    [ThingEvent.ON_DRAW]: 'function onDraw(api, things, children)',
  }[event];
}
