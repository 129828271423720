import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { AppForEditor } from './AppForEditor';
import { getCodeTemplate } from './code';
import { COLORS, DrawingTool, EditorOnlyState, Runtime, Tab } from './types';
import {
  _loadEditorOnlyState,
  initializeEditorOnlyState,
} from './substantiate';
import { Provider } from 'react-redux';
import {
  sharedStateStore,
  useAppDispatch,
  useAppSelector,
  RoomProvider,
  getRuntime,
  ifNoStorageUrlCreateAndEitherWayPutInUrlAndLocalStorage,
  getSimulatorStorageIdInUrl,
  updateAllData,
  getPlayerSlugInUrl,
} from './useData';
import { actions } from '@liveblocks/redux';
import { AppForSimulatorAndPlayer } from './AppForSimulatorAndPlayer';
import { isIOs } from './isIOs';
console.clear();

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <PlayerOrSimulatorOrEditor />
  </React.StrictMode>
);

function PlayerOrSimulatorOrEditor() {
  const runtime = getRuntime(window.location);

  if (runtime === Runtime.PLAYER) {
    return <Player runtime={runtime} />;
  }

  if (runtime === Runtime.SIMULATOR) {
    return <Simulator runtime={runtime} />;
  }

  return <Editor />;
}

function Editor() {
  const [storageId, setStorageId] = useState<string | null>(null);

  useEffect(() => {
    const storageId = ifNoStorageUrlCreateAndEitherWayPutInUrlAndLocalStorage(
      window,
      localStorage
    );
    setStorageId(storageId);
    initializeEditorOnlyState(storageId);
  }, []);

  if (!storageId) {
    return <Loading />;
  }

  const loadedEditorOnlyState =
    (_loadEditorOnlyState() as EditorOnlyState) || getInitialEditorOnlyState();

  return (
    <RoomProvider
      // Super weird - we need to wrap in a RoomProvider so we can use
      // useEventListener to receive RoomEvents, but we still need enterRoom
      // below to actually enter the right room
      id={storageId}
      initialPresence={() => ({})}
    >
      <Provider store={sharedStateStore}>
        <LoadSharedState storageId={storageId}>
          <AppForEditor loadedEditorOnlyState={loadedEditorOnlyState} />
        </LoadSharedState>
      </Provider>
    </RoomProvider>
  );
}

function Simulator({ runtime }: { runtime: Runtime }) {
  if (!isIOs()) {
    return <CanOnlyPlayLudeGamesOnIOs />;
  }

  const storageId = getSimulatorStorageIdInUrl(window.location);

  return (
    <RoomProvider id={storageId} initialPresence={() => ({})}>
      <Provider store={sharedStateStore}>
        <LoadSharedState storageId={storageId}>
          <AppForSimulatorAndPlayer runtime={runtime} />
        </LoadSharedState>
      </Provider>
    </RoomProvider>
  );
}

function Player({ runtime }: { runtime: Runtime }) {
  const slug = getPlayerSlugInUrl(window.location);
  const [loadState, setLoadState] = useState('loading');

  fetch(`/gameState/${slug}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      setLoadState('loadingFailed');
      throw new Error('Could not load game');
    })
    .then(({ gameState }) => {
      setLoadState('loadingSucceeded');

      sharedStateStore.dispatch(
        updateAllData({ ...gameState, isPaused: false })
      );
    })
    .catch((error) => {
      console.error(error);
    });

  if (!isIOs()) {
    return <CanOnlyPlayLudeGamesOnIOs />;
  }

  if (loadState === 'loading') {
    return <Loading />;
  }

  if (loadState === 'loadingFailed') {
    return <div className="p1">Could not load game</div>;
  }

  return (
    <Provider store={sharedStateStore}>
      <AppForSimulatorAndPlayer runtime={runtime} />
    </Provider>
  );
}

function LoadSharedState({
  storageId,
  children,
}: {
  storageId: string;
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(
    (state) => state.liveblocks?.isStorageLoading
  );

  useEffect(() => {
    dispatch(actions.enterRoom(storageId));

    return () => {
      dispatch(actions.leaveRoom(storageId));
    };
  }, [dispatch, storageId]);

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
}

function Loading() {
  return <div className="p1">Loading...</div>;
}

function CanOnlyPlayLudeGamesOnIOs() {
  return <div className="p1">Can only play Lude games on iOS</div>;
}

// TODO: Move to substantiate
function getInitialEditorOnlyState() {
  const editorOnlyState: EditorOnlyState = {
    selectedThingsIndices: [],
    selectedTab: Tab.THING,
    conversationInput: '',
    specConversation: [],
    codeConversation: [],
    spec: [],
    specAtTimeOfLastCodeGeneration: [],
    code: getCodeTemplate(),
    codeAtTimeOfLastRun: '',
    indexOfCodeMessageBeingViewed: null,
    codeEditingSelectedThingTypeAndEventTypeIfExists: null,
    selectedColor: COLORS[0],
    selectedTool: DrawingTool.PEN,
  };

  return editorOnlyState;
}
