import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Dimensions, DrawingTool, assertTruthy } from './types';
import { useEditorOnlyStateContext } from './substantiate';

export function DrawingCanvas({
  dimensions,
  existingImage,
  onChange,
}: {
  dimensions: Dimensions;
  existingImage: HTMLImageElement | null;
  onChange: (imageData: string) => void;
}) {
  const canvasRef: RefObject<HTMLCanvasElement> = useRef(null);
  const [areDrawing, setAreDrawing] = useState(false);

  const { editorAppState } = useEditorOnlyStateContext();
  const { selectedColor, selectedTool } = editorAppState;

  const getPosition = useCallback(
    (
      canvas: HTMLCanvasElement,
      event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
    ) => {
      const rect = canvas.getBoundingClientRect();

      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    },
    []
  );

  useEffect(() => {
    if (!canvasRef.current || !existingImage) {
      return;
    }

    canvasRef.current
      .getContext('2d')!
      .drawImage(existingImage, 0, 0, dimensions.width, dimensions.height);
  }, [dimensions.width, dimensions.height, existingImage]);

  const size = 12;

  return (
    <canvas
      ref={canvasRef}
      width={dimensions.width}
      height={dimensions.height}
      style={{ border: 0 }}
      onMouseDown={(e) => {
        setAreDrawing(true);
        assertTruthy(canvasRef.current);

        const position = getPosition(canvasRef.current, e);
        const context = canvasRef.current.getContext('2d')!;

        context.beginPath();
        context.moveTo(position.x, position.y);

        if (selectedTool === DrawingTool.PEN) {
          context.fillStyle = selectedColor;
          context.fillRect(position.x, position.y, size, size);
        } else if (selectedTool === DrawingTool.ERASER) {
          context.clearRect(position.x, position.y, size, size);
        }

        onChange(canvasRef.current.toDataURL());
      }}
      onMouseMove={(e) => {
        if (!areDrawing) {
          return;
        }

        assertTruthy(canvasRef.current);

        const position = getPosition(canvasRef.current, e);

        const context = canvasRef.current.getContext('2d')!;
        context.moveTo(position.x, position.y);

        if (selectedTool === DrawingTool.PEN) {
          context.fillRect(position.x, position.y, size, size);
        } else if (selectedTool === DrawingTool.ERASER) {
          context.clearRect(position.x, position.y, size, size);
        }

        context.closePath();

        onChange(canvasRef.current.toDataURL());
      }}
      onMouseUp={() => {
        setAreDrawing(false);
      }}
    />
  );
}
