import { PropertyEditor } from './PropertyEditor';
import { SelectedElementToolbar } from './SelectedElementToolbar';
import { Api, Thing } from './types';

export function ThingTab({
  selectedThings,
  onDeleteSelectedThing,
  onBringOnScreen,
  onDuplicateSelectedThing,
  api,
}: {
  selectedThings: Array<Thing>;
  onDeleteSelectedThing: () => void;
  onBringOnScreen: () => void;
  onDuplicateSelectedThing: () => void;
  api: Api;
}) {
  return (
    <div className="flex flex-column height-full">
      <div style={{ flex: 'none' }}>
        <SelectedElementToolbar
          selectedThings={selectedThings}
          onDeleteThing={onDeleteSelectedThing}
          onBringOnScreen={onBringOnScreen}
          onDuplicateThing={onDuplicateSelectedThing}
          api={api}
        />
      </div>

      <div className="flex flex-column height-full" style={{ width: 500 }}>
        <div className="mt1 rounded border-box" style={{ flex: 1 }}>
          {selectedThings.length === 1 ? (
            <PropertyEditor />
          ) : selectedThings.length === 0 ? (
            <div className="stronger">Nothing selected</div>
          ) : (
            <div className="stronger">More than one thing selected</div>
          )}
        </div>
      </div>
    </div>
  );
}
