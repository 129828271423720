// Fixes bug where changing browser zoom causes a "ResizeObserver loop limit
// exceeded" error in Monaco editor. There's an issue and open PR in the Monaco
// repo about this: https://github.com/microsoft/vscode/issues/183324

export function fixResizeObserverLoopLimitExceededInMonaco() {
  // Save a reference to the original ResizeObserver
  const OriginalResizeObserver = window.ResizeObserver;

  // Create a new ResizeObserver constructor
  // @ts-ignore-next-line
  window.ResizeObserver = function (callback) {
    // @ts-ignore-next-line
    const wrappedCallback = (entries, observer) => {
      window.requestAnimationFrame(() => {
        callback(entries, observer);
      });
    };

    // Create an instance of the original ResizeObserver
    // with the wrapped callback
    return new OriginalResizeObserver(wrappedCallback);
  };

  // Copy over static methods, if any
  for (let staticMethod in OriginalResizeObserver) {
    if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
      // @ts-ignore-next-line
      window.ResizeObserver[staticMethod] =
        // @ts-ignore-next-line
        OriginalResizeObserver[staticMethod];
    }
  }
}
