import { useCallback } from 'react';
import { runUpdateThingHandler } from './thingHandlers';
import {
  Api,
  HandlersByType,
  Thing,
  ThingEvent,
  ThingUpdate,
  assertTruthy,
} from './types';
import {
  AppDispatch,
  reportGameEngineThingChangesToServer,
  thingUpdate,
} from './useData';
import { reportThingChangesToPhysicsEngineFromLocal } from './usePhysicsEngineIfIOs';

export function useOnFireEvent({
  dispatch,
  handlersByType,
  physicsEngineIfExists,
}: {
  dispatch: AppDispatch;
  handlersByType: HandlersByType;
  physicsEngineIfExists: any;
}) {
  const onFireEvent = useCallback(
    (
      thing: Thing,
      api: Api,
      // Kind of gross - we make the game pass things when it calls fireEvent.
      // This is so that if the game calls fireEvent in a loop and the recipient
      // of each call modifies things (e.g. nextDay fired on several stocks each
      // of which push to a single array on Game), each fire recipient gets the
      // latest version of things.
      things: Array<Thing>,
      event: string,
      data: { [key: string]: any }
    ) => {
      assertTruthy(physicsEngineIfExists);
      const thingIndex = things.findIndex((t: Thing) => t.id === thing.id);

      runUpdateThingHandler(
        dispatch,
        physicsEngineIfExists,
        handlersByType,
        things[thingIndex],
        ThingEvent.ON_EVENT,
        [api, event, data, things]
      );

      things.forEach((newThing: Thing, i: number) => {
        reportThingChangesToPhysicsEngineFromLocal(
          physicsEngineIfExists,
          things[i],
          newThing
        );

        reportGameEngineThingChangesToServer(
          things[i],
          newThing,
          i,
          (index: number, update: ThingUpdate) =>
            dispatch(thingUpdate({ index, update }))
        );
      });
    },
    [dispatch, handlersByType, physicsEngineIfExists]
  );

  return onFireEvent;
}
