import { CodeMerge, ThingEvent, assertTruthy } from './types';

export function canMerge(code: string, newJustTheCode: string) {
  try {
    parseCodeObject(code);
    JSON.parse(newJustTheCode);

    return true;
  } catch (e) {
    return false;
  }
}

export function parseCodeObject(code: string) {
  const codeObject = JSON.parse(code);

  return codeObject;
}

export function mergeCode(
  code: string,
  newJustTheCode: string,
  codeMerge: CodeMerge
): string {
  assertTruthy(canMerge(code, newJustTheCode));

  const codeObject = parseCodeObject(code);

  const newJustTheCodeObject = JSON.parse(newJustTheCode);

  switch (codeMerge.type) {
    case 'genAll':
      // We want all generated behavior to be attached to a type so general
      // behavior is attached to the game object and not just free floating
      throw new Error('genAll is not allowed');
    case 'genSelected':
      // When we gen, we want to merge in new behavior because we push the LLM
      // to only generate changes for quickness

      assertTruthy(codeMerge.selectedThingTypes.length > 0);

      codeMerge.selectedThingTypes.forEach((thingType) => {
        codeObject[thingType] = {
          ...codeObject[thingType],
          ...newJustTheCodeObject[thingType],
        };
      });

      return JSON.stringify(codeObject, null, 2);
    case 'textEdit':
      codeObject[codeMerge.thingType] = {
        ...codeObject[codeMerge.thingType],
        [codeMerge.eventType]:
          newJustTheCodeObject[codeMerge.thingType][codeMerge.eventType],
      };

      return JSON.stringify(codeObject, null, 2);
    default:
      throw new Error('Unknown codeMerge type');
  }
}

export function deleteHandler(
  existingCode: string,
  thingType: string,
  eventType: ThingEvent
) {
  const codeObject = parseCodeObject(existingCode);

  delete codeObject[thingType][eventType];

  return JSON.stringify(codeObject, null, 2);
}
